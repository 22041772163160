import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";

export const IS_FILTERS_MODAL_OPEN_KEY = "isFiltersModalOpen";

@Injectable({
    providedIn: "root",
})
export class FiltersModalGuard {
    constructor(private router: Router) {}

    public canDeactivate(component: unknown, currentRoute: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const isFiltersModalOpen = currentRoute.queryParams[IS_FILTERS_MODAL_OPEN_KEY];

        if (isFiltersModalOpen) {
            this.router.navigate([], {
                queryParams: { ...currentRoute.queryParams, [IS_FILTERS_MODAL_OPEN_KEY]: undefined },
                queryParamsHandling: "merge",
                skipLocationChange: true,
            });

            return false;
        }

        return true;
    }
}
