import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule, TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE, TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { switchMap } from "rxjs";
import { ActiveCheckinStatus } from "../../models/checkins.model";
import { FlightStatus } from "../../models/flight-conditions.model";
import { ErrorHandlingService } from "../../services/error-handling-service/error-handling-service";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import { FlightStatusExtended, Weight } from "../../state/drone-tower-mobile.state.model";
import { ALTITUDE_WARNING_HEIGHT } from "../../utils/defaults";

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-flight-status-bar",
    standalone: true,
    imports: [CommonModule, IconDirective, LetModule, MatProgressSpinnerModule, SharedI18nModule, SharedUiModule, PushModule],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "droneTowerMobileLib",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../../../assets/i18n/${language}.json`)),
            },
        },
    ],
    templateUrl: "./flight-status-bar.component.html",
    styleUrls: ["./flight-status-bar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightStatusBarComponent implements OnInit {
    private readonly store = inject(Store);
    private readonly translocoService = inject(TranslocoService);
    private readonly translationHelperService = inject(TranslationHelperService);
    private readonly errorHandlingService = inject(ErrorHandlingService);

    protected readonly ALTITUDE_WARNING_HEIGHT = ALTITUDE_WARNING_HEIGHT;
    protected readonly flightStatusParams$ = this.store.select(DroneTowerMobileState.flightStatusParams);
    protected readonly FlightStatus = FlightStatus;
    protected readonly FlightStatusExtended = FlightStatusExtended;
    protected readonly Weight = Weight;
    protected readonly ActiveCheckinStatus = ActiveCheckinStatus;
    protected readonly flightStatus$ = this.store.select(DroneTowerMobileState.flightStatus);
    protected readonly userCheckin$ = this.store.select(DroneTowerMobileState.userCheckin);
    protected readonly flightCategoryLabel$ = this.flightStatusParams$.pipe(
        switchMap((flightStatusParams) =>
            this.translationHelperService.waitForTranslation(`system.${flightStatusParams.flightCategoryTranslationKey}`)
        )
    );
    public ngOnInit() {
        this.flightStatus$.pipe(untilDestroyed(this)).subscribe((flightStatus) => {
            if (flightStatus !== FlightStatusExtended.Error && flightStatus !== FlightStatusExtended.ErrorOutsideSupportedArea) {
                return;
            }
            const errorKey =
                flightStatus === FlightStatusExtended.ErrorOutsideSupportedArea
                    ? "droneTowerMobile.flightStatusOutsideSupportedAreaMessage"
                    : "droneTowerMobile.flightStatusErrorMessage";
            const errorMessage = this.translocoService.translate(errorKey);
            this.errorHandlingService.displayMessage({
                errorMessage,
            });
        });
    }
}
