import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router, RouterLink } from "@angular/router";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { first, map, switchMap } from "rxjs";
import { ErrorHandlingService } from "../../../services/error-handling-service/error-handling-service";
import { HeaderComponent } from "../../../shared/components/header/header.component";
import { DroneTowerMobileActions } from "../../../state/drone-tower-mobile.actions";
import { DroneTowerMobileState } from "../../../state/drone-tower-mobile.state";
import { PilotOperatorsActions } from "../../../state/pilot-operators/pilot-operators.actions";
import { PilotOperatorsState } from "../../../state/pilot-operators/pilot-operators.state";
import {
    CHECKIN_OPERATOR_NUMBER_LENGTH,
    ERROR_MESSAGE_OPERATOR_NAME_ALREADY_EXISTS,
    ERROR_MESSAGE_OPERATOR_NUMBER_ALREADY_EXISTS,
} from "../../../utils/defaults";

interface CheckInOperatorComponentState {
    operatorNumber: string;
    operatorName: string;
    isNumberAlreadyExists: boolean;
    isNameAlreadyExists: boolean;
}

@UntilDestroy()
@Component({
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, RouterLink, SharedUiModule, HeaderComponent, TranslocoModule, IconDirective, LetModule],
    templateUrl: "./check-in-operator.component.html",
    styleUrls: ["./check-in-operator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CheckInOperatorComponent implements OnInit {
    private readonly store = inject(Store);
    private readonly router = inject(Router);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);
    private readonly localStore = inject(LocalComponentStore<CheckInOperatorComponentState>);

    protected readonly userDataOperatorNumber$ = this.store
        .select(DroneTowerMobileState.userData)
        .pipe(map((userData) => userData.operatorNumber));
    protected readonly pilotOperators$ = this.store
        .select(PilotOperatorsState.pilotOperators)
        .pipe(
            map((pilotOperators) =>
                pilotOperators.filter(
                    (operator) => operator.number !== this.store.selectSnapshot(DroneTowerMobileState.userData).operatorNumber
                )
            )
        );
    protected readonly pilotOperatorsError$ = this.store.select(PilotOperatorsState.pilotOperatorsError);
    protected readonly isProcessing$ = this.store.select(PilotOperatorsState.isProcessing);
    protected readonly operatorNumber$ = this.localStore.selectByKey("operatorNumber");
    protected readonly isNumberAlreadyExists$ = this.localStore.selectByKey("isNumberAlreadyExists");
    protected readonly isNameAlreadyExists$ = this.localStore.selectByKey("isNameAlreadyExists");
    protected readonly operatorForm;

    constructor() {
        const isOneTimeOperatorNumber = this.store.selectSnapshot(DroneTowerMobileState.checkInParams).isOneTimeOperatorNumber;
        const operatorNumber = this.store.selectSnapshot(DroneTowerMobileState.checkInParams).operatorNumber;
        this.operatorForm = new FormGroup({
            number: new FormControl<string>(isOneTimeOperatorNumber ? operatorNumber : "", {
                validators: [Validators.maxLength(CHECKIN_OPERATOR_NUMBER_LENGTH)],
                nonNullable: true,
            }),
            addOperator: new FormControl<boolean>(
                {
                    value: false,
                    disabled: !isOneTimeOperatorNumber,
                },
                { nonNullable: true }
            ),
            name: new FormControl<string>("", { validators: Validators.required, nonNullable: true }),
        });
    }

    public ngOnInit() {
        const currentCheckInOperator = this.store.selectSnapshot(DroneTowerMobileState.checkInParams).operatorNumber;
        this.localStore.setState({
            operatorNumber: currentCheckInOperator
                ? currentCheckInOperator
                : this.store.selectSnapshot(DroneTowerMobileState.userData).operatorNumber,
            operatorName: "",
            isNumberAlreadyExists: false,
            isNameAlreadyExists: false,
        });

        this.operatorForm.controls.number.valueChanges.pipe(untilDestroyed(this)).subscribe((operatorNumber) => {
            const addOperator = this.operatorForm.controls.addOperator;
            const name = this.operatorForm.controls.name;

            this.localStore.patchState({
                isNumberAlreadyExists: false,
                operatorNumber,
            });

            if (operatorNumber) {
                addOperator.enable();
            } else {
                addOperator.disable();
                name.disable();
            }
        });

        this.operatorForm.controls.addOperator.valueChanges.pipe(untilDestroyed(this)).subscribe((addOperator) => {
            const name = this.operatorForm.controls.name;
            if (addOperator) {
                name.enable();
            } else {
                name.disable();
            }
        });

        this.operatorForm.controls.name.valueChanges.pipe(untilDestroyed(this)).subscribe((operatorName) => {
            this.localStore.patchState({
                isNameAlreadyExists: false,
                operatorName,
            });
        });
    }

    public ionViewWillEnter() {
        this.store
            .dispatch(PilotOperatorsActions.GetPilotOperators)
            .pipe(
                switchMap(() => this.store.select(PilotOperatorsState.pilotOperatorsError)),
                first(),
                RxjsUtils.filterFalsy(),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                const errorMessage = this.translocoService.translate("droneTowerMobile.genericErrorMessage");
                this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
            });
    }

    protected toggleOperatorNumber(operatorNumber: string, operatorName?: string) {
        if (!operatorNumber) {
            return;
        }
        const currentOperatorNumber = this.localStore.selectSnapshotByKey("operatorNumber");

        if (currentOperatorNumber === operatorNumber) {
            this.localStore.patchState({
                operatorNumber: this.operatorForm.controls.number.value,
            });
        } else {
            this.operatorForm.controls.addOperator.patchValue(false);
            this.localStore.patchState({ isNumberAlreadyExists: false, operatorNumber, operatorName: operatorName ?? "" });
        }
    }

    protected submitOperator() {
        const { name, number, addOperator } = this.operatorForm.controls;
        if (addOperator.value && this.operatorForm.invalid) {
            this.operatorForm.markAllAsTouched();

            return;
        }

        const operatorName = this.localStore.selectSnapshotByKey("operatorName");
        const operatorNumber = this.localStore.selectSnapshotByKey("operatorNumber");

        if (addOperator.disabled || !addOperator.value) {
            this.store.dispatch(
                new DroneTowerMobileActions.SetCheckinParams({
                    operatorName,
                    operatorNumber,
                    isOneTimeOperatorNumber: number.value === operatorNumber,
                })
            );
            this.router.navigate(["/map"]);

            return;
        }

        this.localStore.patchState({
            isNameAlreadyExists: false,
            isNumberAlreadyExists: false,
        });

        this.store
            .dispatch(
                new PilotOperatorsActions.AddPilotOperator({
                    number: number.value,
                    name: name.value,
                })
            )
            .pipe(
                switchMap(() => this.store.select(PilotOperatorsState.addPilotOperatorsError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobile.genericErrorMessage");
                    this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });

                    this.localStore.patchState({
                        isNameAlreadyExists: httpErrorResponse.error.message === ERROR_MESSAGE_OPERATOR_NAME_ALREADY_EXISTS,
                        isNumberAlreadyExists: httpErrorResponse.error.message === ERROR_MESSAGE_OPERATOR_NUMBER_ALREADY_EXISTS,
                    });
                } else {
                    this.store.dispatch(
                        new DroneTowerMobileActions.SetCheckinParams({ operatorName, operatorNumber, isOneTimeOperatorNumber: false })
                    );
                    this.router.navigate(["/map"]);
                }
            });
    }
}
