import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { TilesetStyle } from "@dtm-frontend/shared/map/leaflet";
import { Page } from "@dtm-frontend/shared/ui";
import { DEFAULT_PHONE_COUNTRY_CODE, Logger, MILLISECONDS_IN_SECOND } from "@dtm-frontend/shared/utils";
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext } from "@ngxs/store";
import { Feature, FeatureCollection, Geometry } from "@turf/helpers";
import { LatLng } from "leaflet";
import { catchError, EMPTY, finalize, map, retry, tap } from "rxjs";
import { AirspaceParams } from "../models/airspace-params.model";
import {
    ActiveCheckinEvent,
    ActiveCheckinProperties,
    ActiveCheckinStatus,
    CheckinHistory,
    CheckinHistoryError,
    CheckinMission,
} from "../models/checkins.model";
import { DateRange } from "../models/date-range.model";
import {
    AirspaceElement,
    AirspaceElementPlannedActivity,
    FlightConditionsStatusDetails,
    KpIndexDetails,
    MinMaxRange,
} from "../models/flight-conditions.model";
import { AirspaceParamsStorageService } from "../services/airspace-params/airspace-params-storage.service";
import { AppStateService } from "../services/app-state/app-state.service";
import { BUILD_TYPE_PRODUCTION } from "../services/build-type/build-type.tokens";
import { CapabilitiesApiService } from "../services/capabilities-api/capabilities-api.service";
import { CheckinsApiService } from "../services/checkins-api/checkins-api.service";
import { CheckinsHistoryService } from "../services/checkins-history/checkins-history.service";
import { FlightConditionsApiService } from "../services/flight-conditions-api/flight-conditions-api.service";
import { convertFlightStatusParamsTimeToDateRange } from "../services/flight-status/flight-status-converter";
import { FlightStatusParamsStorageService } from "../services/flight-status/flight-status-params-storage.service";
import { PilotProfileApiService } from "../services/pilot-profile-api/pilot-profile-api.service";
import { ERROR_MESSAGE_CHECKIN_NOT_FOUND } from "../utils/defaults";
import { PushNotificationsService } from "./../services/push-notifications/push-notifications.service";
import { DroneTowerMobileActions } from "./drone-tower-mobile.actions";
import {
    Capabilities,
    CheckInParams,
    DroneTowerFeatures,
    FlightCategoriesTranslationKeysValues,
    FlightCategoriesValues,
    FlightStatusExtended,
    FlightStatusParams,
    FlightStatusState,
    FlightSubcategoriesTranslationKeysValues,
    FlightSubcategoriesValues,
    KpIndexConfirmationState,
    MarkerPosition,
    UserData,
    Weight,
} from "./drone-tower-mobile.state.model";

export interface DroneTowerMobileStateModel {
    flightStatus: FlightStatusState | null;
    flightStatusElevation: MinMaxRange | null;
    flightStatusParams: FlightStatusParams;
    flightConditionsStatusDetails: FlightConditionsStatusDetails | null;
    airspaceElement: AirspaceElement | null;
    kpIndex: KpIndexDetails | null;
    markerPosition: MarkerPosition | null;
    capabilities: Capabilities;
    capabilitiesError: HttpErrorResponse | null;
    kpIndexConfirmation: KpIndexConfirmationState;
    activeCheckins: FeatureCollection<Geometry, ActiveCheckinProperties> | null;
    userCheckin: Feature<Geometry, ActiveCheckinProperties> | null;
    userData: UserData;
    checkinMissions: CheckinMission[];
    checkInParams: CheckInParams;
    isCreateCheckinLoading: boolean;
    checkinMissionsError: HttpErrorResponse | null;
    createCheckinError: HttpErrorResponse | null;
    acknowledgeCheckinError: HttpErrorResponse | null;
    selectedCheckinMission: CheckinMission | null;
    getPilotDataError: HttpErrorResponse | null;
    setPilotDetailsError: HttpErrorResponse | null;
    phoneNumberChangeRequestError: HttpErrorResponse | null;
    phoneNumberVerificationError: HttpErrorResponse | null;
    flightConditionsStatusDetailsError: HttpErrorResponse | null;
    airspaceElementError: HttpErrorResponse | null;
    genericApiError: HttpErrorResponse | null;
    isAppNotResponding: boolean;
    checkinsHistoryList: CheckinHistory[] | null;
    checkinsHistoryListPages: Page | null;
    checkinsHistoryListError: CheckinHistoryError | null;
    mapRedirectPosition: LatLng | null;
    isCheckinsHistoryListProcessing: boolean;
    deleteCheckinHistoryUserNoteError: CheckinHistoryError | null;
    addCheckinsHistoryUserNoteError: CheckinHistoryError | null;
    updateCheckinsHistoryUserNoteError: CheckinHistoryError | null;
    airspaceParams: AirspaceParams;
}

const DEFAULT_RADIUS = 100;
const DEFAULT_START_TIME = 0;
const DEFAULT_DURATION = 30;
const DEFAULT_FLIGHT_HEIGHT = 120;

// NOTE: 2 second is the expected time when systems will be synced, otherwise display error
const ACK_RETRY_CONFIG = { count: 1, delay: 2 * MILLISECONDS_IN_SECOND };

// TODO: DTOWER-590 remove default hardcoded values
export const DRONE_TOWER_MOBILE_DEFAULT_STATE: DroneTowerMobileStateModel = {
    flightStatus: null,
    flightStatusElevation: null,
    flightStatusParams: {
        flightCategory: FlightCategoriesValues.OPEN,
        flightSubcategory: FlightSubcategoriesValues.A1,
        flightCategoryTranslationKey: FlightCategoriesTranslationKeysValues.OPEN,
        flightSubcategoryTranslationKey: FlightSubcategoriesTranslationKeysValues.A1,
        flightHeight: DEFAULT_FLIGHT_HEIGHT,
        uavWeight: Weight.Light,
        radius: DEFAULT_RADIUS,
        startTime: DEFAULT_START_TIME,
        duration: DEFAULT_DURATION,
        areBvlosMissionsVisible: true,
    },
    flightConditionsStatusDetails: null,
    airspaceElement: null,
    markerPosition: null,
    capabilities: {
        categories: [],
        subcategories: {},
        features: [],
        geoserverUrl: "",
    },
    kpIndex: null,
    kpIndexConfirmation: {
        isWarningConfirmed: false,
        isDangerConfirmed: false,
    },
    capabilitiesError: null,
    activeCheckins: null,
    userCheckin: null,
    userData: {
        name: "",
        surname: "",
        pilotNumber: "",
        operatorNumber: "",
        phoneNumber: {
            number: "",
            countryCode: DEFAULT_PHONE_COUNTRY_CODE,
        },
        operatorDataCanBePublished: false,
        isPhoneNumberVerified: false,
    },
    checkinMissions: [],
    checkInParams: {
        operatorNumber: "",
        operatorName: "",
        isOneTimeOperatorNumber: false,
    },
    isCreateCheckinLoading: false,
    checkinMissionsError: null,
    createCheckinError: null,
    acknowledgeCheckinError: null,
    selectedCheckinMission: null,
    getPilotDataError: null,
    setPilotDetailsError: null,
    phoneNumberChangeRequestError: null,
    phoneNumberVerificationError: null,
    flightConditionsStatusDetailsError: null,
    genericApiError: null,
    airspaceElementError: null,
    isAppNotResponding: false,
    checkinsHistoryList: null,
    checkinsHistoryListPages: null,
    checkinsHistoryListError: null,
    mapRedirectPosition: null,
    isCheckinsHistoryListProcessing: false,
    deleteCheckinHistoryUserNoteError: null,
    addCheckinsHistoryUserNoteError: null,
    updateCheckinsHistoryUserNoteError: null,
    airspaceParams: {
        areOtherCheckinsVisible: true,
        areActiveMissionAreasVisible: true,
        areInactiveZonesVisible: false,
        tilesetStyle: TilesetStyle.BaseRoad,
    },
};

@State<DroneTowerMobileStateModel>({
    name: "droneTowerMobileState",
    defaults: DRONE_TOWER_MOBILE_DEFAULT_STATE,
})
@Injectable()
export class DroneTowerMobileState implements NgxsOnInit {
    private readonly flightConditionsApiService = inject(FlightConditionsApiService);
    private readonly checkinsApiService = inject(CheckinsApiService);
    private readonly capabilitiesApiService = inject(CapabilitiesApiService);
    private readonly pilotProfileApiService = inject(PilotProfileApiService);
    private readonly pushNotificationsService = inject(PushNotificationsService);
    private readonly appStateService = inject(AppStateService);
    private readonly flightStatusParamsStorageService = inject(FlightStatusParamsStorageService);
    private readonly airspaceParamsStorageService = inject(AirspaceParamsStorageService);
    private readonly checkinsHistoryService = inject(CheckinsHistoryService);
    private readonly isProductionBuild = inject(BUILD_TYPE_PRODUCTION);

    @Selector()
    public static flightStatus(state: DroneTowerMobileStateModel): FlightStatusState | null {
        return state.flightStatus;
    }

    @Selector()
    public static flightStatusElevation(state: DroneTowerMobileStateModel): MinMaxRange | null {
        return state.flightStatusElevation;
    }

    @Selector()
    public static flightStatusParams(state: DroneTowerMobileStateModel): FlightStatusParams {
        return state.flightStatusParams;
    }

    @Selector()
    public static markerPosition(state: DroneTowerMobileStateModel): MarkerPosition | null {
        return state.markerPosition;
    }

    @Selector()
    public static capabilities(state: DroneTowerMobileStateModel): Capabilities {
        return state.capabilities;
    }

    @Selector()
    public static kpIndex(state: DroneTowerMobileStateModel): KpIndexDetails | null {
        return state.kpIndex;
    }

    @Selector()
    public static kpIndexConfirmationStatus(state: DroneTowerMobileStateModel): KpIndexConfirmationState {
        return state.kpIndexConfirmation;
    }

    @Selector()
    public static capabilitiesError(state: DroneTowerMobileStateModel): HttpErrorResponse | null {
        return state.capabilitiesError;
    }

    @Selector()
    public static activeCheckins(state: DroneTowerMobileStateModel): FeatureCollection<Geometry, ActiveCheckinProperties> | null {
        return state.activeCheckins;
    }

    @Selector()
    public static userCheckin(state: DroneTowerMobileStateModel): Feature<Geometry, ActiveCheckinProperties> | null {
        return state.userCheckin;
    }

    @Selector()
    public static mapRedirectPosition(state: DroneTowerMobileStateModel): LatLng | null {
        return state.mapRedirectPosition;
    }

    @Selector()
    public static userData(state: DroneTowerMobileStateModel): UserData {
        return state.userData;
    }

    @Selector()
    public static getPilotDataError(state: DroneTowerMobileStateModel): HttpErrorResponse | null {
        return state.getPilotDataError;
    }

    @Selector()
    public static setPilotDetailsError(state: DroneTowerMobileStateModel): HttpErrorResponse | null {
        return state.setPilotDetailsError;
    }

    @Selector()
    public static genericApiError(state: DroneTowerMobileStateModel): HttpErrorResponse | null {
        return state.genericApiError;
    }

    @Selector()
    public static createCheckinError(state: DroneTowerMobileStateModel): HttpErrorResponse | null {
        return state.createCheckinError;
    }

    @Selector()
    public static checkinMissions(state: DroneTowerMobileStateModel): CheckinMission[] {
        return state.checkinMissions;
    }

    @Selector()
    public static checkInParams(state: DroneTowerMobileStateModel) {
        return state.checkInParams;
    }

    @Selector()
    public static isCreateCheckinLoading(state: DroneTowerMobileStateModel) {
        return state.isCreateCheckinLoading;
    }

    @Selector()
    public static checkinMissionsError(state: DroneTowerMobileStateModel): HttpErrorResponse | null {
        return state.checkinMissionsError;
    }

    @Selector()
    public static acknowledgeCheckinError(state: DroneTowerMobileStateModel): HttpErrorResponse | null {
        return state.acknowledgeCheckinError;
    }

    @Selector()
    public static selectedCheckinMission(state: DroneTowerMobileStateModel): CheckinMission | null {
        return state.selectedCheckinMission;
    }

    @Selector()
    public static phoneNumberChangeRequestError(state: DroneTowerMobileStateModel): HttpErrorResponse | null {
        return state.phoneNumberChangeRequestError;
    }

    @Selector()
    public static phoneNumberVerificationError(state: DroneTowerMobileStateModel): HttpErrorResponse | null {
        return state.phoneNumberVerificationError;
    }

    @Selector()
    public static airspaceElement(state: DroneTowerMobileStateModel): AirspaceElement | null {
        return state.airspaceElement;
    }

    @Selector()
    public static airspaceElementError(state: DroneTowerMobileStateModel): HttpErrorResponse | null {
        return state.airspaceElementError;
    }

    @Selector()
    public static flightConditionStatusDetails(state: DroneTowerMobileStateModel): FlightConditionsStatusDetails | null {
        return state.flightConditionsStatusDetails;
    }

    @Selector()
    public static flightConditionStatusDetailsError(state: DroneTowerMobileStateModel): HttpErrorResponse | null {
        return state.flightConditionsStatusDetailsError;
    }

    @Selector()
    public static isAppNotResponding(state: DroneTowerMobileStateModel) {
        return state.isAppNotResponding;
    }

    @Selector()
    public static areOtherCheckinsVisible(state: DroneTowerMobileStateModel) {
        return state.airspaceParams.areOtherCheckinsVisible;
    }

    @Selector()
    public static areActiveMissionAreasVisible(state: DroneTowerMobileStateModel) {
        return state.airspaceParams.areActiveMissionAreasVisible;
    }

    @Selector()
    public static areInactiveZonesVisible(state: DroneTowerMobileStateModel) {
        return state.airspaceParams.areInactiveZonesVisible;
    }

    @Selector()
    public static checkinsHistoryList(state: DroneTowerMobileStateModel): CheckinHistory[] | null {
        return state.checkinsHistoryList;
    }

    @Selector()
    public static checkinsHistoryListError(state: DroneTowerMobileStateModel): CheckinHistoryError | null {
        return state.checkinsHistoryListError;
    }

    @Selector()
    public static isCheckinsHistoryListProcessing(state: DroneTowerMobileStateModel): boolean {
        return state.isCheckinsHistoryListProcessing;
    }

    @Selector()
    public static checkinsHistoryListPages(state: DroneTowerMobileStateModel): Page | null {
        return state.checkinsHistoryListPages;
    }

    @Selector()
    public static deleteCheckinHistoryUserNoteError(state: DroneTowerMobileStateModel): CheckinHistoryError | null {
        return state.deleteCheckinHistoryUserNoteError;
    }

    @Selector()
    public static addCheckinsHistoryUserNoteError(state: DroneTowerMobileStateModel): CheckinHistoryError | null {
        return state.addCheckinsHistoryUserNoteError;
    }

    @Selector()
    public static updateCheckinsHistoryUserNoteError(state: DroneTowerMobileStateModel): CheckinHistoryError | null {
        return state.updateCheckinsHistoryUserNoteError;
    }

    @Selector()
    public static tilesetStyle(state: DroneTowerMobileStateModel) {
        return state.airspaceParams.tilesetStyle;
    }

    public static isFeatureAvailable(feature: DroneTowerFeatures) {
        return createSelector([DroneTowerMobileState], (state: DroneTowerMobileStateModel) =>
            state.capabilities.features.includes(feature)
        );
    }

    public async ngxsOnInit(context: StateContext<DroneTowerMobileStateModel>) {
        context.dispatch(DroneTowerMobileActions.GetCapabilities);
    }

    @Action(DroneTowerMobileActions.CreateCheckIn, { cancelUncompleted: true })
    public createCheckIn(context: StateContext<DroneTowerMobileStateModel>, action: DroneTowerMobileActions.CreateCheckIn) {
        const state = context.getState();
        const markerPosition = state.markerPosition;

        if (
            !markerPosition ||
            state.flightStatus === FlightStatusExtended.Error ||
            state.flightStatus === FlightStatusExtended.ErrorOutsideSupportedArea
        ) {
            return;
        }
        context.patchState({
            createCheckinError: null,
            isCreateCheckinLoading: true,
        });

        return this.checkinsApiService
            .createCheckIn(
                state.flightStatusParams,
                markerPosition,
                {
                    ...state.userData,
                    operatorNumber: action.operatorNumber,
                },
                action.anspMessage
            )
            .pipe(
                tap((userCheckin) => {
                    const activeCheckins = context.getState().activeCheckins;
                    if (activeCheckins) {
                        const features = activeCheckins.features.filter((feature) => feature.id !== userCheckin.id);
                        context.patchState({
                            userCheckin,
                            activeCheckins: { ...activeCheckins, features: [...features, userCheckin] },
                            isCreateCheckinLoading: false,
                        });

                        return;
                    }

                    context.patchState({
                        userCheckin,
                        isCreateCheckinLoading: false,
                    });
                }),
                catchError((createCheckinError) => {
                    context.patchState({ createCheckinError, isCreateCheckinLoading: false });

                    return EMPTY;
                })
            );
    }
    @Action(DroneTowerMobileActions.CreateMissionCheckIn, { cancelUncompleted: true })
    public createMissionCheckIn(context: StateContext<DroneTowerMobileStateModel>, action: DroneTowerMobileActions.CreateMissionCheckIn) {
        const state = context.getState();
        const markerPosition = state.markerPosition;
        const missionId = state.selectedCheckinMission?.id;

        if (!markerPosition || !missionId) {
            return;
        }

        context.patchState({
            createCheckinError: null,
            isCreateCheckinLoading: true,
        });

        return this.checkinsApiService
            .createMissionCheckIn(
                state.flightStatusParams,
                markerPosition,
                {
                    ...state.userData,
                    operatorNumber: action.operatorNumber,
                },
                action.anspMessage,
                missionId
            )
            .pipe(
                tap((userCheckin) => {
                    const activeCheckins = context.getState().activeCheckins;
                    if (activeCheckins) {
                        const features = activeCheckins.features.filter((feature) => feature.id !== userCheckin.id);
                        context.patchState({
                            userCheckin,
                            activeCheckins: { ...activeCheckins, features: [...features, userCheckin] },
                            isCreateCheckinLoading: false,
                        });

                        return;
                    }
                    context.patchState({
                        userCheckin,
                        isCreateCheckinLoading: false,
                    });
                }),
                catchError((createCheckinError) => {
                    context.patchState({ createCheckinError, isCreateCheckinLoading: false });

                    return EMPTY;
                })
            );
    }
    @Action(DroneTowerMobileActions.SelectCheckinMission, { cancelUncompleted: true })
    public selectCheckinMission(context: StateContext<DroneTowerMobileStateModel>, action: DroneTowerMobileActions.SelectCheckinMission) {
        const currentFlightStatusParams = context.getState().flightStatusParams;
        const flightStatusParams = {
            ...currentFlightStatusParams,
            flightHeight: action.checkinMission ? action.checkinMission.maxHeight : currentFlightStatusParams.flightHeight,
        };

        context.patchState({
            selectedCheckinMission: action.checkinMission,
            flightStatusParams,
        });
        this.flightStatusParamsStorageService.patchFlightStatusParams(flightStatusParams);
        context.dispatch(new DroneTowerMobileActions.SetFlightStatus());
    }

    @Action(DroneTowerMobileActions.PatchCheckIn, { cancelUncompleted: true })
    public patchCheckIn(context: StateContext<DroneTowerMobileStateModel>, action: DroneTowerMobileActions.PatchCheckIn) {
        const state = context.getState();

        const userCheckin = {
            ...(state.userCheckin as Feature<Geometry, ActiveCheckinProperties>),
            properties: {
                ...(state.userCheckin?.properties as ActiveCheckinProperties),
                ...action.activeCheckIn,
            },
        };

        context.patchState({
            userCheckin,
        });

        return EMPTY;
    }

    @Action(DroneTowerMobileActions.AcknowledgeCheckinModification, { cancelUncompleted: true })
    public acknowledgeCheckInModification(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({ acknowledgeCheckinError: undefined });
        const id = context.getState().userCheckin?.id;
        if (id) {
            return this.checkinsApiService.acknowledgeCheckInModification(id as string).pipe(
                retry(ACK_RETRY_CONFIG),
                catchError((error) => {
                    context.patchState({
                        acknowledgeCheckinError: error,
                    });

                    return EMPTY;
                })
            );
        }

        return EMPTY;
    }

    @Action(DroneTowerMobileActions.AcknowledgeLandNowCheckIn, { cancelUncompleted: true })
    public acknowledgeLandNowCheckIn(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({ acknowledgeCheckinError: undefined });
        const id = context.getState().userCheckin?.id;
        if (id) {
            return this.checkinsApiService.acknowledgeLandNowCheckIn(id as string).pipe(
                retry(ACK_RETRY_CONFIG),
                catchError((error) => {
                    context.patchState({
                        acknowledgeCheckinError: error,
                    });

                    return EMPTY;
                })
            );
        }

        return EMPTY;
    }

    @Action(DroneTowerMobileActions.AcknowledgeCheckInAccepted, { cancelUncompleted: true })
    public acknowledgeCheckInAccepted(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({ acknowledgeCheckinError: undefined });
        const id = context.getState().userCheckin?.id;
        if (id) {
            return this.checkinsApiService.acknowledgeCheckInAccepted(id as string).pipe(
                retry(ACK_RETRY_CONFIG),
                catchError((error) => {
                    context.patchState({
                        acknowledgeCheckinError: error,
                    });

                    return EMPTY;
                })
            );
        }

        return EMPTY;
    }

    @Action(DroneTowerMobileActions.AcknowledgeCheckInRejected, { cancelUncompleted: true })
    public acknowledgeCheckInRejected(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({ acknowledgeCheckinError: undefined });
        const id = context.getState().userCheckin?.id;
        if (id) {
            return this.checkinsApiService.acknowledgeCheckInRejected(id as string).pipe(
                retry(ACK_RETRY_CONFIG),
                catchError((error) => {
                    context.patchState({
                        acknowledgeCheckinError: error,
                    });

                    return EMPTY;
                })
            );
        }

        return EMPTY;
    }

    @Action(DroneTowerMobileActions.SetCheckinMissionList, { cancelUncompleted: true })
    public setCheckinMissionList(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({
            checkinMissionsError: null,
            checkinMissions: [],
        });

        return this.checkinsApiService.getMissionsList().pipe(
            tap((checkinMissions) => context.patchState({ checkinMissions })),
            catchError((error) => {
                context.patchState({
                    checkinMissionsError: error,
                });

                return EMPTY;
            })
        );
    }

    @Action(DroneTowerMobileActions.SetFlightStatus, { cancelUncompleted: true })
    public setFlightStatus(context: StateContext<DroneTowerMobileStateModel>) {
        const state = context.getState();
        if (!state.markerPosition?.latitude || !state.markerPosition?.longitude) {
            return;
        }
        context.patchState({
            flightStatus: FlightStatusExtended.Loading,
        });

        return this.flightConditionsApiService.getFlightStatus().pipe(
            tap((flightStatus) => {
                context.patchState(
                    flightStatus.elevationDetails.elevation
                        ? { flightStatus: flightStatus.status, flightStatusElevation: { ...flightStatus.elevationDetails.elevation } }
                        : { flightStatus: FlightStatusExtended.ErrorOutsideSupportedArea, flightStatusElevation: null }
                );
                context.patchState({
                    kpIndex: {
                        ...flightStatus.kpIndexDetails,
                    },
                });
            }),
            catchError(() => {
                context.patchState({
                    flightStatus: FlightStatusExtended.Error,
                    flightStatusElevation: null,
                });

                return EMPTY;
            })
        );
    }

    @Action(DroneTowerMobileActions.SetMarkerPosition, { cancelUncompleted: true })
    public setMarkerPosition(context: StateContext<DroneTowerMobileStateModel>, action: DroneTowerMobileActions.SetMarkerPosition) {
        context.patchState({
            markerPosition: action.markerPosition,
        });
        context.dispatch(new DroneTowerMobileActions.SetFlightStatus());
    }

    @Action(DroneTowerMobileActions.RemoveMarkerPosition, { cancelUncompleted: true })
    public removeMarkerPosition(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({
            markerPosition: null,
            flightStatus: null,
        });
    }

    @Action(DroneTowerMobileActions.SetFlightStatusParams, { cancelUncompleted: true })
    public setFlightStatusParams(
        context: StateContext<DroneTowerMobileStateModel>,
        { flightStatusParams }: DroneTowerMobileActions.SetFlightStatusParams
    ) {
        const state = context.getState();
        const updatedFlightStatusParams = { ...state.flightStatusParams, ...flightStatusParams };
        context.patchState({
            flightStatusParams: updatedFlightStatusParams,
        });
        this.flightStatusParamsStorageService.patchFlightStatusParams(updatedFlightStatusParams);
        context.dispatch(new DroneTowerMobileActions.SetFlightStatus());
    }

    @Action(DroneTowerMobileActions.GetCapabilities, { cancelUncompleted: true })
    public getCapabilities(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({
            capabilitiesError: null,
        });

        return this.capabilitiesApiService.getCapabilities().pipe(
            tap(async (capabilities: Capabilities) => {
                context.patchState({
                    capabilities: {
                        ...capabilities,
                        features: this.isProductionBuild ? capabilities.features : Object.values(DroneTowerFeatures),
                    },
                });
                const features = context.getState().capabilities.features;
                const flightStatusParams = await this.flightStatusParamsStorageService.getFlightStatusParams();
                const hasBvlosMissions = features.includes(DroneTowerFeatures.BvlosMissions);
                const hasAirspace = features.includes(DroneTowerFeatures.Airspace);
                if (flightStatusParams) {
                    const { areBvlosMissionsVisible } = flightStatusParams;
                    context.patchState({
                        flightStatusParams: {
                            ...flightStatusParams,
                            areBvlosMissionsVisible: hasBvlosMissions ? (hasAirspace ? areBvlosMissionsVisible : true) : false,
                        },
                    });
                }
                const airspaceParams = await this.airspaceParamsStorageService.getAirspaceParams();
                const hasTilesetStyle = features.includes(DroneTowerFeatures.TilesetStyle);
                const hasInactiveZones = features.includes(DroneTowerFeatures.InactiveZones);
                if (airspaceParams) {
                    const { tilesetStyle } = airspaceParams;
                    context.patchState({
                        airspaceParams: {
                            ...airspaceParams,
                            tilesetStyle: hasAirspace && hasTilesetStyle ? tilesetStyle : TilesetStyle.BaseRoad,
                            areInactiveZonesVisible: hasInactiveZones ? airspaceParams.areInactiveZonesVisible : false,
                        },
                    });
                }
            }),
            catchError((capabilitiesError) => {
                context.patchState({
                    capabilitiesError,
                });

                return EMPTY;
            })
        );
    }

    @Action(DroneTowerMobileActions.SetKpIndexWarningConfirmation, { cancelUncompleted: true })
    public setKpIndexWarningConfirmation(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({
            kpIndexConfirmation: {
                isWarningConfirmed: true,
                isDangerConfirmed: false,
            },
        });
    }

    @Action(DroneTowerMobileActions.SetKpIndexDangerConfirmation, { cancelUncompleted: true })
    public setKpIndexDangerConfirmation(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({
            kpIndexConfirmation: {
                isWarningConfirmed: false,
                isDangerConfirmed: true,
            },
        });
    }

    @Action(DroneTowerMobileActions.ResetKpIndexConfirmation, { cancelUncompleted: true })
    public resetKpIndexConfirmation(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({
            kpIndexConfirmation: {
                isWarningConfirmed: false,
                isDangerConfirmed: false,
            },
        });
    }

    @Action(DroneTowerMobileActions.SetActiveCheckins, { cancelUncompleted: true })
    public setActiveCheckins(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({ genericApiError: null });

        return this.checkinsApiService.getActiveCheckins().pipe(
            tap(({ activeCheckins, userCheckin }) => {
                context.patchState({
                    activeCheckins,
                    userCheckin,
                });
                if (userCheckin) {
                    context.patchState({
                        markerPosition: {
                            latitude: userCheckin.properties.latitude,
                            longitude: userCheckin.properties.longitude,
                            isUserPosition: false,
                        },
                    });
                }
            }),
            catchError((genericApiError) => {
                context.patchState({ genericApiError });

                return EMPTY;
            })
        );
    }

    @Action(DroneTowerMobileActions.StopCheckin, { cancelUncompleted: true })
    public stopCheckin(context: StateContext<DroneTowerMobileStateModel>, action: DroneTowerMobileActions.StopCheckin) {
        const state = context.getState();

        if (state.userCheckin?.id) {
            const activeCheckins = state.activeCheckins;

            return this.checkinsApiService.finishCheckIn(state.userCheckin.id as string, action.shouldFinishMissionFromMissionPlanner).pipe(
                tap(() => {
                    context.patchState({
                        userCheckin: null,
                        activeCheckins: activeCheckins
                            ? {
                                  ...activeCheckins,
                                  features: activeCheckins.features.filter((feature) => feature.id !== state.userCheckin?.id),
                              }
                            : null,
                        selectedCheckinMission: null,
                    });
                }),
                catchError((error) => {
                    if (error?.error?.message === ERROR_MESSAGE_CHECKIN_NOT_FOUND) {
                        context.patchState({
                            userCheckin: null,
                            activeCheckins: activeCheckins
                                ? {
                                      ...activeCheckins,
                                      features: activeCheckins.features.filter((feature) => feature.id !== state.userCheckin?.id),
                                  }
                                : null,
                            selectedCheckinMission: null,
                        });
                    }

                    return EMPTY;
                })
            );
        }

        return EMPTY;
    }

    @Action(DroneTowerMobileActions.CheckinLostControl, { cancelUncompleted: true })
    public checkinLostControl(
        context: StateContext<DroneTowerMobileStateModel>,
        action: {
            lostControlData: { battery: number; speed: number };
        }
    ) {
        const state = context.getState();
        context.patchState({
            genericApiError: null,
        });

        if (state.userCheckin?.id) {
            return this.checkinsApiService
                .checkInLostControl(state.userCheckin.id as string, {
                    velocity: action.lostControlData.speed,
                    estimatedBatteryLifetime: action.lostControlData.battery,
                })
                .pipe(
                    tap((userCheckin) => {
                        const activeCheckins = context.getState().activeCheckins;
                        if (activeCheckins) {
                            context.patchState({
                                userCheckin,
                                activeCheckins: { ...activeCheckins, features: [...activeCheckins.features, userCheckin] },
                            });

                            return;
                        }

                        context.patchState({
                            userCheckin,
                        });
                    }),
                    catchError((genericApiError) => {
                        context.patchState({
                            genericApiError,
                        });

                        return EMPTY;
                    })
                );
        }

        return EMPTY;
    }

    @Action(DroneTowerMobileActions.ActiveCheckinsWatch, { cancelUncompleted: true })
    public activeCheckinsWatch(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({
            genericApiError: null,
        });

        return this.checkinsApiService.startActiveCheckinsWatch().pipe(
            tap((message) => {
                if (message.type === ActiveCheckinEvent.CheckinRefreshEvent) {
                    context.dispatch(new DroneTowerMobileActions.SetActiveCheckins());
                } else {
                    const activeCheckins = context.getState().activeCheckins;
                    if (!activeCheckins || !message.body) {
                        return;
                    }
                    const features = activeCheckins.features.filter((feature) => feature.id !== message.body?.id);
                    if (message.type === ActiveCheckinEvent.CheckinFinishedEvent) {
                        context.patchState({
                            activeCheckins: { ...activeCheckins, features: [...features] },
                        });

                        return;
                    }
                    const userCheckin = context.getState().userCheckin;
                    if (message.body?.id === userCheckin?.id) {
                        context.patchState({
                            userCheckin: message.body,
                        });
                    }
                    context.patchState({
                        activeCheckins: { ...activeCheckins, features: [...features, message.body] },
                    });
                }
            }),
            catchError((genericApiError) => {
                Logger.captureException(genericApiError);
                context.patchState({ genericApiError });

                return EMPTY;
            })
        );
    }

    @Action(DroneTowerMobileActions.PatchUserData, { cancelUncompleted: true })
    public patchUserData(context: StateContext<DroneTowerMobileStateModel>, action: DroneTowerMobileActions.PatchUserData) {
        const userData = context.getState().userData;

        context.patchState({
            userData: {
                ...userData,
                ...action.userData,
            },
        });
    }

    @Action(DroneTowerMobileActions.GetPilotProfilePhoneNumber, { cancelUncompleted: true })
    public getPilotProfilePhoneNumber(context: StateContext<DroneTowerMobileStateModel>) {
        const userData = context.getState().userData;
        context.patchState({ getPilotDataError: null });

        return this.pilotProfileApiService.getPilotData().pipe(
            tap((pilotPhoneNumber) =>
                context.patchState({
                    userData: {
                        ...userData,
                        name: pilotPhoneNumber.name,
                        surname: pilotPhoneNumber.surname,
                        phoneNumber: pilotPhoneNumber.phoneNumber,
                        isPhoneNumberVerified: pilotPhoneNumber.isVerified,
                    },
                })
            ),
            catchError((getPilotDataError) => {
                context.patchState({
                    getPilotDataError,
                });

                return EMPTY;
            })
        );
    }

    @Action(DroneTowerMobileActions.SetPilotDetails, { cancelUncompleted: true })
    public setPilotDetails(context: StateContext<DroneTowerMobileStateModel>, action: DroneTowerMobileActions.SetPilotDetails) {
        const userData = context.getState().userData;
        context.patchState({ setPilotDetailsError: null });

        return this.pilotProfileApiService.setPilotDetails(action.pilotDetailsRequestPayload).pipe(
            tap(() =>
                context.patchState({
                    userData: {
                        ...userData,
                        ...action.pilotDetailsRequestPayload,
                    },
                })
            ),
            catchError((setPilotDetailsError) => {
                context.patchState({
                    setPilotDetailsError,
                });

                return EMPTY;
            })
        );
    }

    @Action(DroneTowerMobileActions.VerifyPhoneNumber)
    public verifyPhoneNumber(context: StateContext<DroneTowerMobileStateModel>, action: DroneTowerMobileActions.VerifyPhoneNumber) {
        const userData = context.getState().userData;
        context.patchState({
            phoneNumberVerificationError: null,
        });

        return this.pilotProfileApiService.verifyPhoneNumber(action.verificationCode).pipe(
            tap(() =>
                context.patchState({
                    userData: { ...userData, isPhoneNumberVerified: true },
                })
            ),
            catchError((phoneNumberVerificationError) => {
                context.patchState({
                    phoneNumberVerificationError,
                });

                return EMPTY;
            })
        );
    }

    @Action(DroneTowerMobileActions.RequestPhoneNumberChange)
    public requestPhoneNumberChange(
        context: StateContext<DroneTowerMobileStateModel>,
        action: DroneTowerMobileActions.RequestPhoneNumberChange
    ) {
        context.patchState({
            phoneNumberChangeRequestError: null,
        });
        const userData = context.getState().userData;

        return this.pilotProfileApiService.requestPhoneNumberChange(action.phoneNumber).pipe(
            tap(() =>
                context.patchState({
                    userData: { ...userData, isPhoneNumberVerified: false, phoneNumber: action.phoneNumber },
                })
            ),
            catchError((phoneNumberChangeRequestError) => {
                context.patchState({
                    phoneNumberChangeRequestError,
                });

                return EMPTY;
            })
        );
    }

    @Action(DroneTowerMobileActions.SetActiveCheckinOverdue, { cancelUncompleted: true })
    public setActiveCheckinOverdue(context: StateContext<DroneTowerMobileStateModel>) {
        const state = context.getState();

        const userCheckin = {
            ...(state.userCheckin as Feature<Geometry, ActiveCheckinProperties>),
            properties: {
                ...(state.userCheckin?.properties as ActiveCheckinProperties),
                status: ActiveCheckinStatus.Overdue,
            },
        };

        const activeCheckins = state.activeCheckins;
        if (activeCheckins) {
            context.patchState({
                userCheckin,
                activeCheckins: { ...activeCheckins, features: [...activeCheckins.features, userCheckin] },
            });
        } else {
            context.patchState({
                userCheckin,
            });
        }
    }

    @Action(DroneTowerMobileActions.GetFlightConditionsStatusDetails, { cancelUncompleted: true })
    public getFlightConditionsStatusDetails(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({
            flightConditionsStatusDetailsError: null,
        });

        return this.flightConditionsApiService.getFlightConditionsStatusDetails().pipe(
            tap((flightConditionsStatusDetails) => {
                context.patchState({ flightConditionsStatusDetails });
            }),
            catchError((error) => {
                context.patchState({ flightConditionsStatusDetailsError: error });

                return EMPTY;
            })
        );
    }

    @Action(DroneTowerMobileActions.GetAirspaceElement, { cancelUncompleted: true })
    public getAirspaceElement(context: StateContext<DroneTowerMobileStateModel>, action: DroneTowerMobileActions.GetAirspaceElement) {
        context.patchState({ airspaceElementError: null });
        const flightStatusParams = context.getState().flightStatusParams;

        return this.flightConditionsApiService.getAirspaceElement(action.designator).pipe(
            tap((airspaceElement) => {
                context.patchState({
                    airspaceElement: {
                        ...airspaceElement,
                        plannedActivities: airspaceElement.plannedActivities
                            .sort((left, right) => new Date(left.startDateTime).getTime() - new Date(right.startDateTime).getTime())
                            .map((plannedActivity) => this.isPlannedActivityInFlightRange(plannedActivity, flightStatusParams)),
                    },
                });
            }),
            catchError((error) => {
                context.patchState({ airspaceElementError: error });

                return EMPTY;
            })
        );
    }

    @Action(DroneTowerMobileActions.GetCheckinsHistory, { cancelUncompleted: true })
    public getCheckinsHistory(context: StateContext<DroneTowerMobileStateModel>, action: DroneTowerMobileActions.GetCheckinsHistory) {
        context.patchState({ isCheckinsHistoryListProcessing: true });

        return this.checkinsHistoryService.getCheckinsHistory(action.params).pipe(
            tap((checkinsHistory) =>
                context.patchState({
                    checkinsHistoryListError: null,
                    checkinsHistoryListPages: {
                        pageSize: checkinsHistory.pageSize,
                        pageNumber: checkinsHistory.pageNumber,
                        totalElements: checkinsHistory.totalElements,
                    },
                    checkinsHistoryList: action.shouldResetList
                        ? checkinsHistory.content
                        : (context.getState().checkinsHistoryList ?? []).concat(checkinsHistory.content),
                })
            ),
            catchError((error) => {
                context.patchState({ checkinsHistoryListError: error, checkinsHistoryList: null });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isCheckinsHistoryListProcessing: false }))
        );
    }

    @Action(DroneTowerMobileActions.ClearCheckinsHistory, { cancelUncompleted: true })
    public ClearCheckinsHistory(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({
            isCheckinsHistoryListProcessing: false,
            checkinsHistoryListPages: undefined,
            checkinsHistoryList: null,
            checkinsHistoryListError: null,
            deleteCheckinHistoryUserNoteError: null,
        });

        return EMPTY;
    }

    @Action(DroneTowerMobileActions.RefreshAppState, { cancelUncompleted: true })
    public refreshAppState(context: StateContext<DroneTowerMobileStateModel>) {
        return context.dispatch(DroneTowerMobileActions.SetActiveCheckins).pipe(
            tap(() => {
                this.pushNotificationsService.handleUserCheckinToNotification();
                context.dispatch(new DroneTowerMobileActions.SetFlightStatus());
                this.appStateService.registerAppStateChange();
            })
        );
    }

    private isAirspaceInFlightRange(activity: DateRange, flightRange: DateRange) {
        return (
            (activity.endDate >= flightRange.startDate && activity.startDate <= flightRange.endDate) ||
            (activity.startDate >= flightRange.startDate && activity.startDate <= flightRange.endDate) ||
            (activity.startDate < flightRange.startDate && activity.endDate > flightRange.endDate)
        );
    }

    private isPlannedActivityInFlightRange(plannedActivity: AirspaceElementPlannedActivity, flightStatusParams: FlightStatusParams) {
        if (flightStatusParams) {
            plannedActivity.isInFlightRange = this.isAirspaceInFlightRange(
                {
                    startDate: new Date(plannedActivity.startDateTime),
                    endDate: new Date(plannedActivity.endDateTime),
                },
                convertFlightStatusParamsTimeToDateRange(flightStatusParams)
            );
        }

        return plannedActivity;
    }

    @Action(DroneTowerMobileActions.AppNotResponding, { cancelUncompleted: true })
    public appNotResponding(context: StateContext<DroneTowerMobileStateModel>) {
        context.patchState({ isAppNotResponding: true });
    }

    @Action(DroneTowerMobileActions.SetOtherCheckinsVisibility, { cancelUncompleted: true })
    public setOtherCheckinsVisibility(
        context: StateContext<DroneTowerMobileStateModel>,
        { areOtherCheckinsVisible }: DroneTowerMobileActions.SetOtherCheckinsVisibility
    ) {
        const currentAirspaceParams = context.getState().airspaceParams;
        const airspaceParams = { ...currentAirspaceParams, areOtherCheckinsVisible };
        context.patchState({ airspaceParams });
        this.airspaceParamsStorageService.patchAirspaceParams(airspaceParams);
    }

    @Action(DroneTowerMobileActions.SetActiveMissionAreasVisibility, { cancelUncompleted: true })
    public setActiveMissionAreasVisibility(
        context: StateContext<DroneTowerMobileStateModel>,
        { areActiveMissionAreasVisible }: DroneTowerMobileActions.SetActiveMissionAreasVisibility
    ) {
        const currentAirspaceParams = context.getState().airspaceParams;
        const airspaceParams = { ...currentAirspaceParams, areActiveMissionAreasVisible };
        context.patchState({ airspaceParams });
        this.airspaceParamsStorageService.patchAirspaceParams(airspaceParams);
    }

    @Action(DroneTowerMobileActions.SetInactiveZonesVisibility, { cancelUncompleted: true })
    public setInactiveGeozonesVisibility(
        context: StateContext<DroneTowerMobileStateModel>,
        { areInactiveZonesVisible }: DroneTowerMobileActions.SetInactiveZonesVisibility
    ) {
        const currentAirspaceParams = context.getState().airspaceParams;
        const airspaceParams = { ...currentAirspaceParams, areInactiveZonesVisible };
        context.patchState({ airspaceParams });
        this.airspaceParamsStorageService.patchAirspaceParams(airspaceParams);
    }

    @Action(DroneTowerMobileActions.SetMapRedirectPosition, { cancelUncompleted: true })
    public setMapRedirectPosition(
        context: StateContext<DroneTowerMobileStateModel>,
        action: DroneTowerMobileActions.SetMapRedirectPosition
    ) {
        context.patchState({ mapRedirectPosition: action.position });
    }

    @Action(DroneTowerMobileActions.DeleteCheckinHistoryUserNote, { cancelUncompleted: true })
    public deleteCheckinHistoryUserNote(
        context: StateContext<DroneTowerMobileStateModel>,
        action: DroneTowerMobileActions.DeleteCheckinHistoryUserNote
    ) {
        context.patchState({ deleteCheckinHistoryUserNoteError: null, isCheckinsHistoryListProcessing: true });

        return this.checkinsHistoryService.deleteCheckinHistoryUserNote(action.checkinId).pipe(
            map(() => {
                const isList = context.getState().checkinsHistoryList !== null;
                const list = isList ? [...(context.getState().checkinsHistoryList as CheckinHistory[])] : [];
                const itemIndexOnList = list?.findIndex((checkin) => checkin.id === action.checkinId);

                if (itemIndexOnList !== -1) {
                    list[itemIndexOnList] = { ...list[itemIndexOnList], userNote: undefined };
                    context.patchState({ checkinsHistoryList: list });
                }

                return EMPTY;
            }),
            catchError((error) => {
                context.patchState({ deleteCheckinHistoryUserNoteError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isCheckinsHistoryListProcessing: false }))
        );
    }

    @Action(DroneTowerMobileActions.AddCheckinHistoryUserNote, { cancelUncompleted: true })
    public addCheckinHistoryUserNote(
        context: StateContext<DroneTowerMobileStateModel>,
        action: DroneTowerMobileActions.AddCheckinHistoryUserNote
    ) {
        context.patchState({ addCheckinsHistoryUserNoteError: null, isCheckinsHistoryListProcessing: true });

        return this.checkinsHistoryService.addCheckinHistoryUserNote(action.note).pipe(
            map((updatedCheckin) => {
                this.assignNewCheckinHistoryItemToList(context, updatedCheckin);

                return EMPTY;
            }),
            catchError((error) => {
                context.patchState({ addCheckinsHistoryUserNoteError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isCheckinsHistoryListProcessing: false }))
        );
    }

    @Action(DroneTowerMobileActions.UpdateCheckinHistoryUserNote, { cancelUncompleted: true })
    public updateCheckinHistoryUserNote(
        context: StateContext<DroneTowerMobileStateModel>,
        action: DroneTowerMobileActions.UpdateCheckinHistoryUserNote
    ) {
        context.patchState({ updateCheckinsHistoryUserNoteError: null, isCheckinsHistoryListProcessing: true });

        return this.checkinsHistoryService.updateCheckinHistoryUserNote(action.note).pipe(
            map((updatedCheckin) => {
                this.assignNewCheckinHistoryItemToList(context, updatedCheckin);

                return EMPTY;
            }),
            catchError((error) => {
                context.patchState({ updateCheckinsHistoryUserNoteError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isCheckinsHistoryListProcessing: false }))
        );
    }

    @Action(DroneTowerMobileActions.SetTilesetStyle, { cancelUncompleted: true })
    public setTilesetStyle(context: StateContext<DroneTowerMobileStateModel>, { tilesetStyle }: DroneTowerMobileActions.SetTilesetStyle) {
        const currentAirspaceParams = context.getState().airspaceParams;
        const airspaceParams = { ...currentAirspaceParams, tilesetStyle };
        context.patchState({ airspaceParams });
        this.airspaceParamsStorageService.patchAirspaceParams(airspaceParams);
    }

    private assignNewCheckinHistoryItemToList(context: StateContext<DroneTowerMobileStateModel>, updatedCheckin: CheckinHistory) {
        const isList = context.getState().checkinsHistoryList !== null;
        const list = isList ? [...(context.getState().checkinsHistoryList as CheckinHistory[])] : [];
        const itemIndexOnList = list?.findIndex((checkin) => checkin.id === updatedCheckin.id);

        if (itemIndexOnList !== -1) {
            list[itemIndexOnList] = updatedCheckin;
            context.patchState({ checkinsHistoryList: list });
        }
    }

    @Action(DroneTowerMobileActions.SetCheckinParams, { cancelUncompleted: true })
    public setCheckinParams(
        context: StateContext<DroneTowerMobileStateModel>,
        { checkInParams }: DroneTowerMobileActions.SetCheckinParams
    ) {
        const currentCheckInParams = context.getState().checkInParams;
        context.patchState({ checkInParams: { ...currentCheckInParams, ...checkInParams } });
    }
}
