import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Directive, Input, OnInit } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { GeoJsonObject, Polygon } from "geojson";
import { GeoJSON, Layer } from "leaflet";
import { MapService } from "../map/map.service";

interface MissionAreaDirectiveState {
    missionGeometry: Polygon[] | null | undefined;
    isCheckinOpen: boolean;
}

const DEFAULT_MISSION_AREA_COLOR = "#7800B0";

@UntilDestroy()
@Directive({
    selector: "drone-tower-mobile-lib-mission-area[missionGeometry][isCheckinOpen]",
    providers: [LocalComponentStore],
})
export class MissionAreaDirective implements OnInit {
    @Input() public set missionGeometry(value: Polygon[] | null | undefined) {
        this.localStore.patchState({ missionGeometry: value });
    }

    @Input() public set isCheckinOpen(value: boolean | undefined) {
        this.localStore.patchState({
            isCheckinOpen: coerceBooleanProperty(value),
        });
    }

    private readonly mission$ = this.localStore.selectByKey("missionGeometry");
    private readonly isCheckinOpen$ = this.localStore.selectByKey("isCheckinOpen");
    private layer: Layer | null = null;

    constructor(private readonly mapService: MapService, private readonly localStore: LocalComponentStore<MissionAreaDirectiveState>) {
        this.localStore.setState({
            missionGeometry: null,
            isCheckinOpen: false,
        });
    }

    public ngOnInit() {
        this.mission$.pipe(untilDestroyed(this)).subscribe((missionCoordinates) => {
            if (missionCoordinates) {
                this.addAreaToMap(missionCoordinates);

                return;
            }
            if (this.layer) {
                this.mapService.mapInstance?.removeLayer(this.layer);
            }
        });
        this.isCheckinOpen$.pipe(untilDestroyed(this)).subscribe((isOpen) => {
            if (!isOpen && this.layer) {
                this.mapService.mapInstance?.removeLayer(this.layer);

                return;
            }
            const missionGeometry = this.localStore.selectSnapshotByKey("missionGeometry");
            if (isOpen && missionGeometry) {
                this.addAreaToMap(missionGeometry);

                return;
            }
        });
    }

    private addAreaToMap(area: Polygon[]) {
        if (this.layer) {
            this.mapService.mapInstance?.removeLayer(this.layer);
        }

        const areaFeature = area.map((polygon: Polygon) => ({
            type: "Feature",
            geometry: {
                ...polygon,
            },
        })) as unknown as GeoJsonObject;

        const style = {
            color: DEFAULT_MISSION_AREA_COLOR,
        };

        this.layer = new GeoJSON(areaFeature, { style });

        this.mapService.mapInstance?.addLayer(this.layer);
    }
}
