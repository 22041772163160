import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetDirective } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { first, switchMap } from "rxjs";
import { ErrorHandlingService } from "../../../services/error-handling-service/error-handling-service";
import { HeaderComponent } from "../../../shared/components/header/header.component";
import { PilotOperatorsActions } from "../../../state/pilot-operators/pilot-operators.actions";
import { PilotOperatorsState } from "../../../state/pilot-operators/pilot-operators.state";
import { CHECKIN_OPERATOR_NUMBER_LENGTH } from "../../../utils/defaults";

interface UpsertOperatorComponentState {
    mode: Mode | undefined;
    operatorName: string | undefined;
}

enum Mode {
    Insert = "Insert",
    Update = "Update",
}

@UntilDestroy()
@Component({
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedUiModule,
        HeaderComponent,
        TranslocoModule,
        LetDirective,
        RouterLink,
        MatInputModule,
    ],
    templateUrl: "./upsert-operator.component.html",
    styleUrls: ["./upsert-operator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UpsertOperatorComponent implements OnInit {
    private readonly store = inject(Store);
    private readonly router = inject(Router);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly localStore: LocalComponentStore<UpsertOperatorComponentState> = inject(
        LocalComponentStore<UpsertOperatorComponentState>
    );

    protected readonly Mode = Mode;
    protected readonly mode$ = this.localStore.selectByKey("mode");
    protected readonly isProcessing$ = this.store.select(PilotOperatorsState.isProcessing);
    protected readonly pilotOperators$ = this.store.select(PilotOperatorsState.pilotOperators);
    protected readonly pilotOperatorsError$ = this.store.select(PilotOperatorsState.pilotOperatorsError);
    protected readonly operatorForm = new FormGroup({
        number: new FormControl<string>("", {
            validators: [Validators.required, Validators.maxLength(CHECKIN_OPERATOR_NUMBER_LENGTH)],
            nonNullable: true,
        }),
        name: new FormControl<string>("", { validators: Validators.required, nonNullable: true }),
    });

    public ngOnInit() {
        const operatorName = this.activatedRoute.snapshot.paramMap.get("operatorName");
        if (operatorName) {
            const pilotOperator = this.store.selectSnapshot(PilotOperatorsState.pilotOperatorsByName(operatorName));

            this.operatorForm.patchValue({ name: pilotOperator?.name, number: pilotOperator?.number });

            this.localStore.setState({
                mode: Mode.Update,
                operatorName,
            });
        } else {
            this.localStore.setState({
                mode: Mode.Insert,
                operatorName: undefined,
            });
        }
    }

    protected submitOperator() {
        const { name, number } = this.operatorForm.controls;

        if (this.operatorForm.invalid) {
            this.operatorForm.markAllAsTouched();

            return;
        }

        const mode = this.localStore.selectSnapshotByKey("mode");

        if (mode === Mode.Insert) {
            this.store
                .dispatch(
                    new PilotOperatorsActions.AddPilotOperator({
                        number: number.value,
                        name: name.value,
                    })
                )
                .pipe(
                    switchMap(() => this.store.select(PilotOperatorsState.addPilotOperatorsError)),
                    first(),
                    untilDestroyed(this)
                )
                .subscribe((httpErrorResponse) => {
                    if (httpErrorResponse) {
                        const errorMessage = this.translocoService.translate("droneTowerMobile.genericErrorMessage");
                        this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                    } else {
                        this.router.navigate(["/operators"], { replaceUrl: true });
                    }
                });
        } else {
            const operatorName = this.localStore.selectSnapshotByKey("operatorName");
            this.store
                .dispatch(
                    new PilotOperatorsActions.UpdatePilotOperator(operatorName ?? name.value, {
                        number: number.value,
                        name: name.value,
                    })
                )
                .pipe(
                    switchMap(() => this.store.select(PilotOperatorsState.updatePilotOperatorsError)),
                    first(),
                    untilDestroyed(this)
                )
                .subscribe((httpErrorResponse) => {
                    if (httpErrorResponse) {
                        const errorMessage = this.translocoService.translate("droneTowerMobile.genericErrorMessage");
                        this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                    } else {
                        this.router.navigate(["/operators"], { replaceUrl: true });
                    }
                });
        }
    }
}
