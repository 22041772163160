import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule, TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoModule } from "@jsverse/transloco";
import { PushPipe } from "@ngrx/component";
import { first } from "rxjs";

export interface AcknowledgeCheckInModificationDialogConfig {
    maxHeight: string;
    startDate: Date;
    stopDate: Date;
}

@Component({
    selector: "drone-tower-mobile-lib-check-in-dialog",
    standalone: true,
    imports: [CommonModule, MatDialogModule, SharedI18nModule, SharedUiModule, TranslocoModule, PushPipe],
    templateUrl: "./acknowledge-check-in-modification-dialog.component.html",
    styleUrls: ["./acknowledge-check-in-modification-dialog.component.scss", "../../../styles/simple-check-in-dialog.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcknowledgeCheckInModificationDialogComponent {
    private readonly translationHelperService = inject(TranslationHelperService);
    protected readonly translationsReady$ = this.translationHelperService
        .waitForTranslation("droneTowerMobileLib.acknowledgeCheckinModificationDialog.headerLabel")
        .pipe(first());

    constructor(
        public dialogRef: MatDialogRef<AcknowledgeCheckInModificationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AcknowledgeCheckInModificationDialogConfig
    ) {}
}
