<div
    *ngrxLet="{ flightStatus: flightStatus$, flightParams: flightStatusParams$, userCheckin: userCheckin$ } as vm"
    class="status-bar"
    [class.active]="vm.userCheckin"
    [class.lost-control]="vm.userCheckin?.properties?.status === ActiveCheckinStatus.LostControl"
    [class.overdue]="vm.userCheckin?.properties?.status === ActiveCheckinStatus.Overdue"
>
    <ng-container [ngSwitch]="vm.flightStatus">
        <dtm-ui-icon *ngSwitchCase="FlightStatus.NoRestrictionInfo" name="error-warning-fill" class="no-restriction"></dtm-ui-icon>
        <dtm-ui-icon *ngSwitchCase="FlightStatus.NoRestriction" name="checkbox-circle-fill" class="no-restriction"></dtm-ui-icon>
        <dtm-ui-icon *ngSwitchCase="FlightStatus.Prohibited" name="sign-prohibition" class="prohibited"></dtm-ui-icon>
        <dtm-ui-icon *ngSwitchCase="FlightStatus.Conditional" name="error-warning-fill" class="conditional"></dtm-ui-icon>
        <dtm-ui-icon *ngSwitchCase="FlightStatusExtended.Error" name="question" class="conditional"></dtm-ui-icon>
        <dtm-ui-icon *ngSwitchCase="FlightStatusExtended.ErrorOutsideSupportedArea" name="question" class="conditional"></dtm-ui-icon>
        <mat-spinner *ngSwitchCase="FlightStatusExtended.Loading" [diameter]="20" color="accent"></mat-spinner>
        <dtm-ui-icon *ngSwitchDefault name="information-fill" class="no-status"></dtm-ui-icon>
    </ng-container>
    <ng-container *ngIf="vm.userCheckin as userCheckin; else checkinInactiveTemplate">
        <span class="checkin-divider"></span>
        <ng-container [ngSwitch]="userCheckin.properties.status">
            <ng-container *ngSwitchCase="ActiveCheckinStatus.LostControl">
                <dtm-ui-icon name="error-warning" class="prohibited"></dtm-ui-icon>
                <p>{{ "droneTowerMobileLib.statusBar.uavLostControlLabel" | transloco }}</p>
            </ng-container>
            <ng-container *ngSwitchCase="ActiveCheckinStatus.Overdue">
                <dtm-ui-icon name="error-warning" class="overdue"></dtm-ui-icon>
                <p>{{ "droneTowerMobileLib.statusBar.overdueLabel" | transloco }}</p>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <dtm-ui-icon name="play-circle" class="no-restriction"></dtm-ui-icon>
                <p>{{ "droneTowerMobileLib.statusBar.inProgress" | transloco }}</p>
            </ng-container>
        </ng-container>
        <span class="checkin-divider"></span>
        <dtm-ui-icon name="complex-top-height"></dtm-ui-icon>
        <p>{{ userCheckin.properties.maxHeight }} {{ "droneTowerMobileLib.flightStatusForm.units.metersAgl" | transloco }}</p>
        <span class="checkin-divider"></span>
        <dtm-ui-icon name="flight-land" class="land-icon"></dtm-ui-icon>
        <p>{{ userCheckin.properties.stopDate | localizeDate : { hour: "numeric", minute: "numeric" } }}</p>
    </ng-container>
    <ng-template #checkinInactiveTemplate>
        <!-- TODO: DTOWER-383 Global Translation Issue-->
        <p>
            {{ flightCategoryLabel$ | ngrxPush }}
        </p>
        <span class="checkin-divider"></span>
        <dtm-ui-icon name="complex-top-height"></dtm-ui-icon>
        <p>
            <span [class.warning]="vm.flightParams.flightHeight > ALTITUDE_WARNING_HEIGHT">{{
                vm.flightParams.flightHeight | localizeNumber
            }}</span>
            {{ "droneTowerMobileLib.flightStatusForm.units.metersAgl" | transloco }}
        </p>
        <span class="checkin-divider"></span>
        <dtm-ui-icon name="drone"></dtm-ui-icon>
        <p>
            {{
                "droneTowerMobileLib.weightValueLabel"
                    | transloco : { value: vm.flightParams.uavWeight === Weight.Light ? 1 : vm.flightParams.uavWeight }
            }}
        </p>
    </ng-template>
</div>
