<drone-tower-mobile-lib-header [headerLabel]="'droneTowerMobileLib.airspace.title' | transloco"></drone-tower-mobile-lib-header>

<div class="container">
    <div class="label">{{ "droneTowerMobileLib.airspace.mapLayers" | transloco }}</div>
    <div class="row">
        {{ "droneTowerMobileLib.airspace.showOtherCheckins" | transloco }}
        <mat-slide-toggle
            [checked]="areOtherCheckinsVisible$ | ngrxPush"
            (change)="toggleOtherCheckinsVisibility($event)"
        ></mat-slide-toggle>
    </div>
    <div class="row" *ngIf="isBvlosMissionsFeatureAvailable$ | ngrxPush">
        {{ "droneTowerMobileLib.airspace.showBvlosRegions" | transloco }}
        <mat-slide-toggle
            [checked]="areBvlosMissionsVisible$ | ngrxPush"
            (change)="toggleBvlosMissionsVisibility($event)"
        ></mat-slide-toggle>
    </div>
    <div class="row">
        {{ "droneTowerMobileLib.airspace.showActiveMissionsAreas" | transloco }}
        <mat-slide-toggle
            [checked]="areActiveMissionAreasVisible$ | ngrxPush"
            (change)="toggleOtherCheckinAreasVisibility($event)"
        ></mat-slide-toggle>
    </div>
    <div class="row">
        {{ "droneTowerMobileLib.airspace.showInactiveZones" | transloco }}
        <mat-slide-toggle
            [checked]="areInactiveZonesVisible$ | ngrxPush"
            (change)="toggleInactiveZonesVisibility($event)"
        ></mat-slide-toggle>
    </div>
    <ng-container *ngIf="isTileStyleFeatureAvailable$ | ngrxPush">
        <div class="label">{{ "droneTowerMobileLib.airspace.mapStyle" | transloco }}</div>
        <div class="tiles" *ngrxLet="tilesetStyle$ as tilesetStyle">
            <button
                class="tile-style-button"
                [ngClass]="{ active: tilesetStyle === TilesetStyle.BaseRoad }"
                type="button"
                (click)="setTilesetStyle(TilesetStyle.BaseRoad)"
            >
                <img src="assets/images/map_standard.png" alt="" />
                {{ "droneTowerMobileLib.airspace.standardMap" | transloco }}
            </button>
            <button
                class="tile-style-button"
                [ngClass]="{ active: tilesetStyle === TilesetStyle.ImageryBaseHybridRoad }"
                type="button"
                (click)="setTilesetStyle(TilesetStyle.ImageryBaseHybridRoad)"
            >
                <img src="assets/images/map_satellite.png" alt="" />
                {{ "droneTowerMobileLib.airspace.satelliteMap" | transloco }}
            </button>
        </div>
    </ng-container>
</div>
