import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Directive, Inject, Input, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider, createCanvasIconMarker } from "@dtm-frontend/shared/map/leaflet";
import { ArrayUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Feature, FeatureCollection, Geometry, featureCollection } from "@turf/helpers";
import equal from "fast-deep-equal";
import { BaseIconOptions, Circle, FeatureGroup, GeoJSON as GeoJsonLayer, Icon, Layer, Map as LeafletMap, Marker } from "leaflet";
import { combineLatest, throttleTime } from "rxjs";
import { ActiveCheckinProperties, ActiveCheckinStatus } from "../../../../models/checkins.model";
import { ActiveCheckinDetailsPopupComponent } from "./../../../../components/active-checkin-details-modal/active-checkin-details-popup.component";

const ICON_OPTIONS: BaseIconOptions = {
    /* eslint-disable no-magic-numbers */
    iconSize: [50, 50],
    iconAnchor: [25, 25],
    popupAnchor: [0, -15],
    /* eslint-enable */
};

const POPUP_WIDTH = 300;
const LAYER_REFRESH_THROTTLE_TIME = 300;

const DEFAULT_CHECKIN_AREA_COLOR = "#3388ff";
const DEFAULT_MISSION_AREA_COLOR = "#7800B0";

const ICON_SUBPATH_PER_STATUS: Partial<Record<ActiveCheckinStatus, string>> = {
    [ActiveCheckinStatus.Accepted]: "accepted",
    [ActiveCheckinStatus.AcceptedAck]: "accepted",
    [ActiveCheckinStatus.Active]: "active",
    [ActiveCheckinStatus.AtcModified]: "atc_modified",
    [ActiveCheckinStatus.AtcModifiedAck]: "atc_modified",
    [ActiveCheckinStatus.AtcWaiting]: "atc_waiting",
    [ActiveCheckinStatus.Created]: "created",
    [ActiveCheckinStatus.LandNow]: "land_now",
    [ActiveCheckinStatus.LandNowAck]: "land_now",
    [ActiveCheckinStatus.LostControl]: "lost_control",
    [ActiveCheckinStatus.Overdue]: "overdue",
    [ActiveCheckinStatus.Rejected]: "rejected",
    [ActiveCheckinStatus.RejectedAck]: "rejected",
    [ActiveCheckinStatus.Seen]: "seen",
};

interface ActiveCheckinsDirectiveState {
    activeCheckins: FeatureCollection<Geometry, ActiveCheckinProperties> | null | undefined;
    userCheckin: Feature<Geometry, ActiveCheckinProperties> | null | undefined;
    areOtherCheckinsVisible: boolean;
    areActiveMissionAreasVisible: boolean;
}

@UntilDestroy()
@Directive({
    selector: "drone-tower-mobile-lib-active-checkins[activeCheckins]",
    providers: [LocalComponentStore],
})
export class ActiveCheckinsDirective implements OnInit, OnDestroy {
    @Input() public set activeCheckins(value: FeatureCollection<Geometry, ActiveCheckinProperties> | null | undefined) {
        this.localStore.patchState({ activeCheckins: value });
    }

    @Input() public set userCheckin(value: Feature<Geometry, ActiveCheckinProperties> | null | undefined) {
        this.localStore.patchState({ userCheckin: value });
    }

    @Input() public set areOtherCheckinsVisible(value: BooleanInput) {
        this.localStore.patchState({
            areOtherCheckinsVisible: coerceBooleanProperty(value),
        });
    }

    @Input() public set areActiveMissionAreasVisible(value: BooleanInput) {
        this.localStore.patchState({
            areActiveMissionAreasVisible: coerceBooleanProperty(value),
        });
    }

    private readonly activeCheckins$ = this.localStore.selectByKey("activeCheckins");
    private readonly areOtherCheckinsVisible$ = this.localStore.selectByKey("areOtherCheckinsVisible");
    private readonly areActiveMissionAreasVisible$ = this.localStore.selectByKey("areActiveMissionAreasVisible");
    private readonly userCheckin$ = this.localStore.selectByKey("userCheckin");

    private readonly checkinIconsLayer = new FeatureGroup();
    private readonly checkinAreasLayer = new FeatureGroup();
    private readonly currentCheckinsMap = new Map<
        string,
        { checkin: ActiveCheckinProperties; checkinLayer: Layer; checkinAreaLayer?: GeoJsonLayer }
    >();
    private readonly detailsComponent = this.viewContainerRef.createComponent(ActiveCheckinDetailsPopupComponent);

    constructor(
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly localStore: LocalComponentStore<ActiveCheckinsDirectiveState>
    ) {
        this.localStore.setState({
            activeCheckins: null,
            userCheckin: null,
            areOtherCheckinsVisible: false,
            areActiveMissionAreasVisible: false,
        });
    }

    public async ngOnInit() {
        const map: LeafletMap = await this.mapProvider.getMap();
        this.initializeGroupLayers(map);

        combineLatest([
            this.activeCheckins$.pipe(RxjsUtils.filterFalsy()),
            this.areOtherCheckinsVisible$,
            this.areActiveMissionAreasVisible$,
            this.userCheckin$,
        ])
            .pipe(throttleTime(LAYER_REFRESH_THROTTLE_TIME, undefined, { leading: true, trailing: true }), untilDestroyed(this))
            .subscribe(([activeCheckins, areOtherCheckinsVisible, areActiveMissionAreasVisible, userCheckin]) =>
                this.updateMarkers(activeCheckins, areOtherCheckinsVisible, areActiveMissionAreasVisible, userCheckin)
            );
    }

    public async ngOnDestroy() {
        // NOTE: when map will be removed by other source we can skip cleanup
        try {
            const map: LeafletMap = await this.mapProvider.getMap();

            map.removeLayer(this.checkinIconsLayer);
            map.removeLayer(this.checkinAreasLayer);
        } catch (error) {}

        this.detailsComponent.destroy();
    }

    private initializeGroupLayers(map: LeafletMap) {
        map.addLayer(this.checkinAreasLayer);
        map.addLayer(this.checkinIconsLayer);

        this.checkinIconsLayer.bindPopup(this.detailsComponent.location.nativeElement, {
            closeButton: true,
            className: "checkin-popup",
            minWidth: POPUP_WIDTH,
            maxWidth: POPUP_WIDTH,
        });

        this.checkinIconsLayer.on("click", () => {
            this.checkinIconsLayer.openPopup();
        });
    }

    private async updateMarkers(
        activeCheckins: FeatureCollection<Geometry, ActiveCheckinProperties>,
        areOtherCheckinsVisible: boolean,
        areActiveMissionAreasVisible: boolean,
        userCheckin?: Feature<Geometry, ActiveCheckinProperties> | null
    ) {
        activeCheckins = {
            ...activeCheckins,
            features: areOtherCheckinsVisible
                ? activeCheckins.features.map((checkin) => ({
                      ...checkin,
                      properties: { ...checkin.properties, status: this.getTransformedStatus(checkin.properties.status) },
                  }))
                : userCheckin
                ? [
                      {
                          ...userCheckin,
                          properties: {
                              ...userCheckin?.properties,
                              status: this.getTransformedStatus(userCheckin.properties.status),
                          },
                      },
                  ]
                : [],
        };

        const newActiveCheckinsMap = new Map<string, Feature<Geometry, ActiveCheckinProperties>>(
            activeCheckins.features.map((checkin) => [checkin.id, checkin] as [string, Feature<Geometry, ActiveCheckinProperties>])
        );
        const [checkinsToUpdateOrSkip, checkinsToAdd] = ArrayUtils.partition([...newActiveCheckinsMap.keys()], (id) =>
            this.currentCheckinsMap.has(id)
        );
        const [checkinsToRemove] = ArrayUtils.partition([...this.currentCheckinsMap.keys()], (id) => !newActiveCheckinsMap.has(id));
        const checkinsToUpdate = checkinsToUpdateOrSkip.filter(
            (id) => !equal(this.currentCheckinsMap.get(id)?.checkin, newActiveCheckinsMap.get(id))
        );

        [...checkinsToRemove, ...checkinsToUpdate].forEach((id) => {
            const checkin = this.currentCheckinsMap.get(id);

            if (checkin) {
                this.checkinIconsLayer.removeLayer(checkin.checkinLayer);
                if (checkin.checkinAreaLayer) {
                    this.checkinAreasLayer.removeLayer(checkin.checkinAreaLayer);
                }
            }

            if (
                checkin?.checkin.id &&
                this.detailsComponent.instance.activeCheckinDetails?.id === checkin.checkin.id &&
                checkinsToRemove.includes(checkin.checkin.id)
            ) {
                this.checkinIconsLayer.closePopup();
                this.detailsComponent.instance.activeCheckinDetails = null;
            }

            this.currentCheckinsMap.delete(id);
        });

        [...checkinsToAdd, ...checkinsToUpdate].forEach((id) => {
            const checkin = newActiveCheckinsMap.get(id);

            if (!checkin) {
                return;
            }

            const isActiveUserCheckin = id === userCheckin?.id;

            this.addCheckinLayers(id, isActiveUserCheckin, checkin, areActiveMissionAreasVisible);
        });
        this.checkinAreasLayer.bringToBack();
    }

    private addCheckinLayers(
        id: string,
        isActiveUserCheckin: boolean,
        checkin: Feature<Geometry, ActiveCheckinProperties>,
        areActiveMissionAreasVisible: boolean
    ) {
        const statusIconPath = ICON_SUBPATH_PER_STATUS[checkin.properties.status];
        if (!statusIconPath) {
            return;
        }
        const iconUrl = `assets/images/drone-status-${statusIconPath}.svg`;

        let checkinLayer: Layer;

        if (isActiveUserCheckin) {
            checkinLayer = new Marker([checkin.properties.latitude, checkin.properties.longitude], {
                icon: new Icon({
                    iconUrl,
                    ...ICON_OPTIONS,
                    className: `pulse pulse-${this.getTransformedStatus(checkin.properties.status)}`,
                }),
                zIndexOffset: 1000,
            });
        } else {
            checkinLayer = createCanvasIconMarker([checkin.properties.latitude, checkin.properties.longitude], {
                icon: {
                    url: iconUrl,
                    size: ICON_OPTIONS.iconSize as [number, number],
                },
                fillOpacity: 1,
            });
        }

        checkinLayer.on("click", () => {
            this.detailsComponent.instance.activeCheckinDetails = checkin.properties;
        });

        if (this.detailsComponent.instance.activeCheckinDetails?.id === checkin.properties.id) {
            this.detailsComponent.instance.activeCheckinDetails = checkin.properties;
        }

        if (
            !(checkin.properties.missionArea && checkin.properties.missionArea.length) ||
            areActiveMissionAreasVisible ||
            isActiveUserCheckin
        ) {
            const checkinAreaLayer = new GeoJsonLayer(this.convertFeatureToAreaFeature(checkin), {
                style: (geoJsonFeature) => ({
                    color: geoJsonFeature?.geometry.type === "Point" ? DEFAULT_CHECKIN_AREA_COLOR : DEFAULT_MISSION_AREA_COLOR,
                }),
                pointToLayer: (geoJsonPoint, latLng) => new Circle(latLng, { radius: geoJsonPoint.properties.radius }),
            });

            this.checkinAreasLayer.addLayer(checkinAreaLayer);

            this.currentCheckinsMap.set(id, { checkin: checkin.properties, checkinLayer, checkinAreaLayer });
        } else {
            this.currentCheckinsMap.set(id, { checkin: checkin.properties, checkinLayer });
        }

        this.checkinIconsLayer.addLayer(checkinLayer);
    }

    private getTransformedStatus(status: ActiveCheckinStatus): ActiveCheckinStatus {
        status = status.split("_ack")[0] as ActiveCheckinStatus;

        return status;
    }

    private convertFeatureToAreaFeature(feature: Feature<Geometry, ActiveCheckinProperties>) {
        if (feature.properties.missionArea && feature.properties.missionArea.length) {
            const features: Feature<Geometry, ActiveCheckinProperties>[] = feature.properties.missionArea.map((missionArea) => ({
                type: "Feature" as const,
                geometry: missionArea,
                properties: feature.properties,
            }));

            return featureCollection(features);
        } else {
            return featureCollection([feature]);
        }
    }
}
