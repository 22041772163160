export class CQLFilterBuilder {
    private conditions: string[] = [];

    public addTimeRangeCondition(startTime: string, endTime: string): this {
        this.conditions.push(`(end_time >= ${startTime} and end_time <= ${endTime}) or
            (start_time >= ${startTime} and start_time <= ${endTime}) or
            (start_time < ${startTime} and end_time > ${endTime})`);

        return this;
    }

    public addHeightLimit(flightHeight: number): this {
        this.conditions.push(`lower_limit < ${flightHeight}`);

        return this;
    }

    public includeBvlos(includeBvlos: boolean): this {
        if (!includeBvlos) {
            this.conditions.push("type <> 'BVLOS'");
        }

        return this;
    }

    public addSurfaceAreaCondition(minSurfaceArea: number): this {
        if (minSurfaceArea) {
            this.conditions.push(`surface_area > ${minSurfaceArea} or type = 'RPA'`);
        }

        return this;
    }

    public addLayerCondition(includeInactiveZones: boolean): this {
        const layers = includeInactiveZones ? "'active-airspace', 'missions', 'full-airspace'" : "'active-airspace', 'missions'";
        this.conditions.push(`layer in (${layers})`);

        return this;
    }

    public build(): string {
        if (this.conditions.length === 0) {
            return "";
        }

        return this.conditions.map((condition) => `(${condition})`).join(" and ");
    }
}
