<ng-container
    *ngrxLet="{
        checkinsHistory: checkinsHistory$,
        checkinHistoryError: checkinsHistoryListError$,
        isProcessing: isProcessing$,
        checkinsHistoryListPages: checkinsHistoryListPages$,
        filters: filters$,
        isFiltersModalOpen: isFiltersModalOpen$
    } as vm"
>
    <header>
        <button type="button" class="back-button button-icon" routerLink="">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
        <h2>
            {{ "droneTowerMobileLib.checkinsHistory.header" | transloco : { number: vm.checkinsHistoryListPages.totalElements } }}
        </h2>
        <button type="button" class="button-icon" (click)="openFiltersModal()">
            <dtm-ui-icon name="filter"></dtm-ui-icon>
        </button>
    </header>
    <dtm-ui-loader-container [isShown]="vm.isProcessing">
        <dtm-ui-error *ngIf="vm.checkinHistoryError" (reload)="getFirstPage()"></dtm-ui-error>
        <ion-content *ngIf="vm.checkinsHistory?.length; else emptyStateTemplate">
            <ion-list>
                <ion-item *ngFor="let checkinHistoryItem of vm.checkinsHistory; let index = index; trackBy: trackByIndex">
                    <drone-tower-mobile-lib-checkin-history-item
                        [checkinHistoryItem]="checkinHistoryItem"
                        (mapRedirect)="redirectToMap($event)"
                        (noteDelete)="deleteNote($event)"
                        (noteAdd)="addNote($event)"
                        (noteUpdate)="updateNote($event)"
                    >
                    </drone-tower-mobile-lib-checkin-history-item>
                </ion-item>
            </ion-list>
            <ion-infinite-scroll
                (ionInfinite)="getNextPage()"
                [disabled]="vm.isProcessing"
                *ngIf="vm.checkinsHistoryListPages | invoke : isNotLastPage"
            >
                <ion-infinite-scroll-content> </ion-infinite-scroll-content>
            </ion-infinite-scroll>
        </ion-content>
    </dtm-ui-loader-container>

    <ng-template #emptyStateTemplate>
        <dtm-ui-no-results></dtm-ui-no-results>
    </ng-template>

    <ion-modal #filtersModal [backdropDismiss]="false" [isOpen]="vm.isFiltersModalOpen">
        <ng-template>
            <drone-tower-mobile-lib-checkin-history-filter
                [isProcessing]="vm.isProcessing"
                [checkinsHistoryItemsNumber]="vm.checkinsHistoryListPages.totalElements"
                [initialFilters]="vm.filters"
                (filtersClose)="closeFiltersModal()"
                (filtersChange)="applyFilters($event)"
            ></drone-tower-mobile-lib-checkin-history-filter>
        </ng-template>
    </ion-modal>
</ng-container>
