import { TilesetStyle } from "@dtm-frontend/shared/map/leaflet";
import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { LatLng } from "leaflet";
import { ActiveCheckinProperties, CheckinMission, CheckinsHistoryParams, CheckinsHistoryUserNoteChange } from "../models/checkins.model";
import { PilotDetailsRequestPayload } from "../services/pilot-profile-api/pilot-profile-api.model";
import { CheckInParams, FlightStatusParams, MarkerPosition, UserData } from "./drone-tower-mobile.state.model";

export namespace DroneTowerMobileActions {
    export class SetFlightStatus {
        public static readonly type = "[Drone Tower Mobile] Set Flight Status";
    }

    export class CreateCheckIn {
        public static readonly type = "[Drone Tower Mobile] Create Check-in";

        constructor(public operatorNumber: string, public anspMessage: string) {}
    }

    export class SetMapRedirectPosition {
        public static readonly type = "[Drone Tower Mobile] set map redirect position";

        constructor(public position: LatLng | null) {}
    }

    export class DeleteCheckinHistoryUserNote {
        public static readonly type = "[Drone Tower Mobile] Delete checkin history user note";

        constructor(public checkinId: string) {}
    }

    export class AddCheckinHistoryUserNote {
        public static readonly type = "[Drone Tower Mobile] Add checkin history user note";

        constructor(public note: CheckinsHistoryUserNoteChange) {}
    }

    export class UpdateCheckinHistoryUserNote {
        public static readonly type = "[Drone Tower Mobile] Update checkin history user note";

        constructor(public note: CheckinsHistoryUserNoteChange) {}
    }

    export class CreateMissionCheckIn {
        public static readonly type = "[Drone Tower Mobile] Create Mission Check-in";

        constructor(public operatorNumber: string, public anspMessage: string) {}
    }

    export class PatchCheckIn {
        public static readonly type = "[Drone Tower Mobile] Patch Check-in";

        constructor(public activeCheckIn: Partial<ActiveCheckinProperties>) {}
    }

    export class GetPilotProfilePhoneNumber {
        public static readonly type = "[Drone Tower Mobile] Get Pilot Profile phone number";
    }

    export class SetPilotDetails {
        public static readonly type = "[Drone Tower Mobile] Set pilot details";
        constructor(public pilotDetailsRequestPayload: PilotDetailsRequestPayload) {}
    }

    export class RequestPhoneNumberChange {
        public static readonly type = "[Drone Tower Mobile] Request phone number change";

        constructor(public phoneNumber: PhoneNumber) {}
    }

    export class VerifyPhoneNumber {
        public static readonly type = "[Drone Tower Mobile] Send phone number verification code";

        constructor(public verificationCode: string) {}
    }

    export class SetCheckinMissionList {
        public static readonly type = "[Drone Tower Mobile] Set Check-in mission list";
    }

    export class SelectCheckinMission {
        public static readonly type = "[Drone Tower Mobile] Select check-in mission";

        constructor(public checkinMission: CheckinMission | null) {}
    }

    export class AcknowledgeCheckinModification {
        public static readonly type = "[Drone Tower Mobile] Acknowledge check-in modification";
    }

    export class AcknowledgeLandNowCheckIn {
        public static readonly type = "[Drone Tower Mobile] Acknowledge land now check-in";
    }

    export class AcknowledgeCheckInAccepted {
        public static readonly type = "[Drone Tower Mobile] Acknowledge check-in accepted";
    }

    export class AcknowledgeCheckInRejected {
        public static readonly type = "[Drone Tower Mobile] Acknowledge check-in rejected";
    }

    export class SetMarkerPosition {
        public static readonly type = "[Drone Tower Mobile] Set Marker Position";
        constructor(public markerPosition: MarkerPosition) {}
    }

    export class RemoveMarkerPosition {
        public static readonly type = "[Drone Tower Mobile] Remove Marker Position";
    }

    export class SetFlightStatusParams {
        public static readonly type = "[Drone Tower Mobile] Set Flight Status Params";
        constructor(public flightStatusParams: Partial<FlightStatusParams>) {}
    }

    export class GetCapabilities {
        public static readonly type = "[Drone Tower Mobile] Get Capabilities";
    }

    export class SetKpIndexDangerConfirmation {
        public static readonly type = "[Drone Tower Mobile] Set KP Index Danger Confirmation";
    }

    export class SetKpIndexWarningConfirmation {
        public static readonly type = "[Drone Tower Mobile] Set KP Index Error Confirmation";
    }

    export class ResetKpIndexConfirmation {
        public static readonly type = "[Drone Tower Mobile] Reset KP Index Confirmation";
    }

    export class SetActiveCheckins {
        public static readonly type = "[Drone Tower Mobile] Set Active Checkins";
    }

    export class SetActiveCheckinOverdue {
        public static readonly type = "[Drone Tower Mobile] Set Active Checkin Overdue";
    }

    export class StopCheckin {
        public static readonly type = "[Drone Tower Mobile] Stop Checkin";
        constructor(public shouldFinishMissionFromMissionPlanner: boolean) {}
    }

    export class CheckinLostControl {
        public static readonly type = "[Drone Tower Mobile] Checkin Lost Control";
        constructor(public lostControlData: { battery: number; speed: number }) {}
    }

    export class ActiveCheckinsWatch {
        public static readonly type = "[Drone Tower Mobile] Active checkins watch";
    }

    export class PatchUserData {
        public static readonly type = "[Drone Tower Mobile] Patch User Data";
        constructor(public userData: Partial<UserData>) {}
    }

    export class GetFlightConditionsStatusDetails {
        public static readonly type = "[Drone Tower Mobile] Get Flight Conditions Status Details";
    }

    export class GetAirspaceElement {
        public static readonly type = "[Drone Tower Mobile] Get Airspace Element";

        constructor(public designator: string) {}
    }

    export class RefreshAppState {
        public static readonly type = "[Drone Tower Mobile] Refresh App State";
    }

    export class AppNotResponding {
        public static readonly type = "[Drone Tower Mobile] App Not Responding";
    }

    export class SetOtherCheckinsVisibility {
        public static readonly type = "[Drone Tower Mobile] Other Checkins Visibility";

        constructor(public areOtherCheckinsVisible: boolean) {}
    }

    export class SetActiveMissionAreasVisibility {
        public static readonly type = "[Drone Tower Mobile] Active Mission Areas Visibility";
        constructor(public areActiveMissionAreasVisible: boolean) {}
    }

    export class SetInactiveZonesVisibility {
        public static readonly type = "[Drone Tower Mobile] Set Inactive Zones Visibility";
        constructor(public areInactiveZonesVisible: boolean) {}
    }

    export class GetCheckinsHistory {
        public static readonly type = "[Drone Tower Mobile] Get checkins history";
        constructor(public params: CheckinsHistoryParams, public shouldResetList?: boolean) {}
    }

    export class ClearCheckinsHistory {
        public static readonly type = "[Drone Tower Mobile] Clear checkins history";
    }

    export class SetTilesetStyle {
        public static readonly type = "[Drone Tower Mobile] Set tileset style";
        constructor(public tilesetStyle: TilesetStyle) {}
    }

    export class SetCheckinParams {
        public static readonly type = "[Drone Tower Mobile] Set check in params";
        constructor(public checkInParams: Partial<CheckInParams>) {}
    }
}
