import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    Inject,
    OnDestroy,
    ViewChild,
} from "@angular/core";
import { MatLegacyMenu as MatMenu, MatLegacyMenuTrigger as MatMenuTrigger } from "@angular/material/legacy-menu";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DomUtil, LeafletMouseEvent, Map, Popup } from "leaflet";
import { skip, takeUntil } from "rxjs";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "../../leaflet-map.tokens";
import { WGS84Coordinates } from "../../models/leaflet-map.models";

class MenuPopup extends Popup {
    // eslint-disable-next-line no-underscore-dangle
    protected _contentNode: HTMLElement = DomUtil.create("div", "leaflet-context-menu");
    // eslint-disable-next-line no-underscore-dangle
    protected _container: HTMLElement = this._contentNode;
}

interface LeafletContextMenuComponentState {
    menuElement: MatMenu | null;
    mouseEvent: LeafletMouseEvent | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-map-leaflet-context-menu",
    templateUrl: "./leaflet-context-menu.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class LeafletContextMenuComponent implements OnDestroy, AfterContentInit {
    @ViewChild("menuTrigger", { static: true, read: MatMenuTrigger }) public readonly menuTrigger: MatMenuTrigger | undefined;
    @ContentChild(MatMenu, { static: false }) public set menuElement(value: MatMenu | undefined) {
        if (value) {
            value.overlapTrigger = true;
            value.closed.pipe(takeUntil(this.menuElement$.pipe(skip(1))), untilDestroyed(this)).subscribe(() => {
                this.menuPopup?.close();
            });
        }

        this.localStore.patchState({ menuElement: value });
    }

    private map: Map | undefined;
    private menuPopup: MenuPopup | undefined;

    protected readonly menuElement$ = this.localStore.selectByKey("menuElement");

    public get coordinates(): WGS84Coordinates | undefined {
        const latLng = this.localStore.selectSnapshotByKey("mouseEvent")?.latlng;

        return latLng ? { latitude: latLng.lat, longitude: latLng.lng } : undefined;
    }

    constructor(
        private readonly elementRef: ElementRef,
        private readonly localStore: LocalComponentStore<LeafletContextMenuComponentState>,
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider
    ) {
        this.localStore.setState({
            menuElement: null,
            mouseEvent: undefined,
        });

        this.mapProvider.getMap().then((map) => {
            this.map = map;

            this.map.on("contextmenu", this.openMenu, this);
        });
    }

    public ngAfterContentInit() {
        this.menuPopup = new MenuPopup();
        this.menuPopup.setContent(this.elementRef.nativeElement);
    }

    public ngOnDestroy() {
        this.map?.off("contextmenu", this.openMenu, this);
    }

    private openMenu(mouseEvent: LeafletMouseEvent): void {
        if (!this.map || !this.menuPopup || !this.menuTrigger) {
            throw new Error("dtm-map-leaflet-context-menu component requires <mat-menu> element as a child");
        }

        this.localStore.patchState({ mouseEvent });
        this.menuPopup.setLatLng(mouseEvent.latlng);
        this.menuPopup.openOn(this.map);
        this.menuTrigger.openMenu();
    }
}
