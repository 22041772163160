import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { Feature, Geometry } from "@turf/helpers";
import { Observable, map } from "rxjs";
import {
    ActiveCheckinEvent,
    ActiveCheckinProperties,
    ActiveCheckinWebsocketEvent,
    ActiveCheckins,
    CheckinMission,
} from "../../models/checkins.model";
import { FlightStatusParams, MarkerPosition, UserData } from "../../state/drone-tower-mobile.state.model";
import { StorageService } from "../storage/storage.service";
import {
    LostControlRequestPayload,
    convertActiveCheckinResponseBodyToFeature,
    convertActiveCheckinsResponseBodyToFeatureCollection,
} from "./checkins-api.converter";
import { ActiveCheckinResponseBody, ActiveCheckinsResponseBody, CheckinRequestBody, MissionCheckinRequestBody } from "./checkins-api.model";
import { CHECKINS_ENDPOINTS_API_TOKENS } from "./checkins-api.tokens";

@Injectable({
    providedIn: "root",
})
export class CheckinsApiService {
    private readonly http = inject(HttpClient);
    private readonly storageService = inject(StorageService);
    private readonly apiToken = inject(CHECKINS_ENDPOINTS_API_TOKENS);
    private readonly websocketService = inject(WebsocketService);

    public getActiveCheckins(): Observable<ActiveCheckins> {
        return this.http.get<ActiveCheckinsResponseBody>(this.apiToken.getActive).pipe(
            map((activeCheckinsResponseBody) => ({
                userCheckin: activeCheckinsResponseBody.userCheckin
                    ? convertActiveCheckinResponseBodyToFeature(activeCheckinsResponseBody.userCheckin)
                    : null,
                activeCheckins: convertActiveCheckinsResponseBodyToFeatureCollection(activeCheckinsResponseBody),
            }))
        );
    }

    public startActiveCheckinsWatch(): Observable<ActiveCheckinWebsocketEvent> {
        return this.websocketService
            .watchTopic("/websocket/topic/drone-tower-queue/drone-tower-active-checkins-topic/broadcast", [
                ActiveCheckinEvent.CheckinEvent,
                ActiveCheckinEvent.CheckinFinishedEvent,
                ActiveCheckinEvent.CheckinLostControlEvent,
                ActiveCheckinEvent.CheckinRefreshEvent,
            ])
            .pipe(
                map((message) => ({
                    type: message.headers["event-type"] as ActiveCheckinEvent,
                    body: convertActiveCheckinResponseBodyToFeature(JSON.parse(message.body).checkin),
                }))
            );
    }

    public createCheckIn(flightStatusParams: FlightStatusParams, checkInPosition: MarkerPosition, userData: UserData, anspMessage: string) {
        const startDateTime = new Date();
        startDateTime.setMinutes(startDateTime.getMinutes() + flightStatusParams.startTime);
        const endDateTime = new Date(startDateTime);
        endDateTime.setMinutes(endDateTime.getMinutes() + flightStatusParams.duration);
        const requestBody: CheckinRequestBody = {
            flightCategory: {
                category: flightStatusParams.flightCategory,
                subcategory: flightStatusParams.flightSubcategory,
            },
            operatorNumber: userData.operatorNumber,
            pilotNumber: userData.pilotNumber,
            phoneNumber: userData.phoneNumber,
            phoneNumberPublicationConsent: userData.operatorDataCanBePublished,
            startDateTime,
            endDateTime,
            flightArea: {
                maxHeight: flightStatusParams.flightHeight,
                center: {
                    latitude: checkInPosition.latitude,
                    longitude: checkInPosition.longitude,
                },
                radius: flightStatusParams.radius,
            },
            uavWeight: flightStatusParams.uavWeight,
            messageToAnsp: anspMessage,
        };

        return this.http
            .post<ActiveCheckinResponseBody>(this.apiToken.createCheckIn, requestBody)
            .pipe(map((checkin) => convertActiveCheckinResponseBodyToFeature(checkin)));
    }

    public finishCheckIn(checkinId: string, shouldFinishMissionFromMissionPlanner: boolean) {
        let params = new HttpParams();
        if (shouldFinishMissionFromMissionPlanner) {
            params = params.set("final", shouldFinishMissionFromMissionPlanner);
        }

        return this.http.delete(StringUtils.replaceInTemplate(this.apiToken.finishCheckIn, { checkinId }), { params });
    }

    public createMissionCheckIn(
        flightStatusParams: FlightStatusParams,
        checkInPosition: MarkerPosition,
        userData: UserData,
        anspMessage: string,
        missionId: string
    ): Observable<Feature<Geometry, ActiveCheckinProperties>> {
        const startDateTime = new Date();
        startDateTime.setMinutes(startDateTime.getMinutes() + flightStatusParams.startTime);
        const endDateTime = new Date(startDateTime);
        endDateTime.setMinutes(endDateTime.getMinutes() + flightStatusParams.duration);
        const requestBody: MissionCheckinRequestBody = {
            flightCategory: {
                category: flightStatusParams.flightCategory,
                subcategory: flightStatusParams.flightSubcategory,
            },
            operatorNumber: userData.operatorNumber,
            pilotNumber: userData.pilotNumber,
            phoneNumber: userData.phoneNumber,
            phoneNumberPublicationConsent: userData.operatorDataCanBePublished,
            startDateTime,
            endDateTime,
            checkinPosition: {
                latitude: checkInPosition.latitude,
                longitude: checkInPosition.longitude,
            },
            uavWeight: flightStatusParams.uavWeight,
            messageToAnsp: anspMessage,
            maxHeight: flightStatusParams.flightHeight,
        };

        return this.http
            .post<ActiveCheckinResponseBody>(StringUtils.replaceInTemplate(this.apiToken.createMissionCheckIn, { missionId }), requestBody)
            .pipe(map((checkin) => convertActiveCheckinResponseBodyToFeature(checkin)));
    }

    public checkInLostControl(checkinId: string, lostControlData: LostControlRequestPayload) {
        return this.http
            .post<ActiveCheckinResponseBody>(
                StringUtils.replaceInTemplate(this.apiToken.checkInLostControl, { checkinId }),
                lostControlData
            )
            .pipe(map((checkin) => convertActiveCheckinResponseBodyToFeature(checkin)));
    }

    public getMissionsList(): Observable<CheckinMission[]> {
        return this.http.get<CheckinMission[]>(this.apiToken.getCheckinMissions);
    }

    public acknowledgeCheckInModification(id: string): Observable<void> {
        return this.http.post<void>(StringUtils.replaceInTemplate(this.apiToken.acknowledgeCheckInModification, { id }), {});
    }

    public acknowledgeLandNowCheckIn(id: string): Observable<void> {
        return this.http.post<void>(StringUtils.replaceInTemplate(this.apiToken.acknowledgeLandNowCheckIn, { id }), {});
    }

    public acknowledgeCheckInAccepted(id: string): Observable<void> {
        return this.http.post<void>(StringUtils.replaceInTemplate(this.apiToken.acknowledgeCheckInAccepted, { id }), {});
    }

    public acknowledgeCheckInRejected(id: string): Observable<void> {
        return this.http.post<void>(StringUtils.replaceInTemplate(this.apiToken.acknowledgeCheckInRejected, { id }), {});
    }
}
