import { Page, PhoneNumber } from "@dtm-frontend/shared/ui";
import { Feature, FeatureCollection, Geometry, Polygon } from "@turf/helpers";
import { ActiveCheckin } from "../services/checkins-api/checkins-api.model";
import { FlightCategoriesValues } from "../state/drone-tower-mobile.state.model";

export interface ActiveCheckins {
    userCheckin: Feature<Geometry, ActiveCheckinProperties> | null;
    activeCheckins: FeatureCollection<Geometry, ActiveCheckinProperties>;
}

export interface ActiveCheckinProperties {
    id: string;
    status: ActiveCheckinStatus;
    startDate: Date;
    stopDate: Date;
    maxHeight: number;
    radius: number;
    latitudeDegreesMinutesSeconds: string;
    latitude: number;
    longitudeDegreesMinutesSeconds: string;
    longitude: number;
    phoneNumber: PhoneNumber;
    missionArea?: Polygon[];
    isAcknowledgeRequired: boolean;
    isFromMissionPlanner: boolean;
}

export type UserCheckin = ActiveCheckin;

export enum ActiveCheckinStatus {
    Created = "created",
    Active = "active",
    LostControl = "lost_control",
    Accepted = "accepted",
    AcceptedAck = "accepted_ack",
    AtcModified = "atc_modified",
    AtcModifiedAck = "atc_modified_ack",
    LandNow = "land_now",
    LandNowAck = "land_now_ack",
    Rejected = "rejected",
    RejectedAck = "rejected_ack",
    Overdue = "overdue",
    AtcWaiting = "atc_waiting",
    Cancelled = "cancelled",
    Finished = "finished",
    Seen = "seen",
    Unknown = "unknown",
}

export enum CreateCheckinErrorType {
    NotAllowedWithoutMission = "drone-tower.checkin.checkin-not-allowed-without-mission",
    OutsideMissionArea = "drone-tower.checkin.checkin-outside-mission-area",
    OutsideMissionTimeframe = "drone-tower.checkin.checkin-outside-mission-timeframe",
    NotAllowedInAlertZone = "drone-tower.checkin.checkin-not-allowed-in-alert-zone",
}

export enum ActiveCheckinEvent {
    CheckinEvent = "CheckinEvent",
    CheckinFinishedEvent = "CheckinFinishedEvent",
    CheckinLostControlEvent = "CheckinLostControlEvent",
    CheckinRefreshEvent = "CheckinRefreshEvent",
}

export interface ActiveCheckinWebsocketEvent {
    type: ActiveCheckinEvent;
    body: Feature<Geometry, ActiveCheckinProperties> | undefined;
}

export interface CheckinMission {
    id: string;
    name: string;
    flightType: string;
    startDateTime: Date;
    endDateTime: Date;
    flightCategoryName: FlightCategoriesValues;
    maxHeight: number;
    missionZones: Polygon[];
    geometry: string;
}

export interface CheckinHistory {
    id: string;
    startDateTime: Date;
    endDateTime: Date;
    overdueInMinutes: number;
    maxHeight: number;
    radius?: number;
    coordinates: Coordinates;
    missionName?: string;
    userNote?: string;
}

export interface Coordinates {
    latitude: number;
    longitude: number;
}

export interface CheckinHistoryError {
    type: CheckinHistoryErrorType;
}

export enum CheckinHistoryErrorType {
    Unknown = "Unknown",
}

export interface CheckinHistoryListWithPageable extends Page {
    content: CheckinHistory[];
}

export interface CheckinsHistoryParams {
    page?: number;
    dateFilters?: CheckinsHistoryFilters;
}

export interface CheckinsHistoryFilters {
    dateFrom: Date | null;
    dateTo: Date | null;
}

export interface CheckinsHistoryUserNoteChange {
    checkinId: string;
    noteValue: string;
}
