import { CommonModule } from "@angular/common";
import { HttpStatusCode } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { Router, RouterLink } from "@angular/router";
import { DataChangeActionType, DataUpdateAction, FormatPhoneNumberPipe, PhoneNumber, SharedUiModule } from "@dtm-frontend/shared/ui";
import { DEFAULT_PHONE_COUNTRY_CODE, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetDirective, PushPipe } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs";
import { ErrorHandlingService } from "../../services/error-handling-service/error-handling-service";
import { UserDataService } from "../../services/user-data/user-data.service";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { NameInputFieldComponent } from "../../shared/components/name-input-field/name-input-field.component";
import { DroneTowerMobileActions } from "../../state/drone-tower-mobile.actions";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import { DroneTowerFeatures } from "../../state/drone-tower-mobile.state.model";
import { NAME_MAX_LENGTH_VALIDATION, NAME_MIN_LENGTH_VALIDATION, NAME_PATTERN_VALIDATION } from "../../utils/defaults";

interface UserDataFormModel {
    name: FormControl<string>;
    surname: FormControl<string>;
    pilotNumber: FormControl<string>;
    operatorNumber: FormControl<string>;
    operatorDataCanBePublished: FormControl<boolean>;
}

interface UserDataComponentState {
    isRequestedPhoneChange: boolean;
    phoneNumber: PhoneNumber;
    isLoading: boolean;
    isError: boolean;
}

const PILOT_NUMBER_MAX_LENGTH = 30;
const OPERATOR_NUMBER_MAX_LENGTH = 16;

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-user-data",
    standalone: true,
    imports: [
        CommonModule,
        HeaderComponent,
        ReactiveFormsModule,
        PushPipe,
        TranslocoModule,
        RouterLink,
        LetDirective,
        SharedUiModule,
        NameInputFieldComponent,
        MatInputModule,
    ],
    templateUrl: "./user-data.component.html",
    styleUrls: ["./user-data.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore, FormatPhoneNumberPipe],
})
export class UserDataComponent {
    private readonly userDataService = inject(UserDataService);
    private readonly store = inject(Store);
    private readonly router = inject(Router);
    private readonly toastService = inject(ToastrService);
    private readonly transloco = inject(TranslocoService);
    private readonly formatPhoneNumberPipe = inject(FormatPhoneNumberPipe);
    private readonly errorHandlingService = inject(ErrorHandlingService);

    protected DEFAULT_PHONE_COUNTRY_CODE = DEFAULT_PHONE_COUNTRY_CODE;
    protected readonly droneTowerVerificationCodeMask = "0 0 0 0 0";

    protected userDataForm: FormGroup<UserDataFormModel> = new FormGroup({
        name: new FormControl<string>("", {
            validators: [
                Validators.required,
                Validators.minLength(NAME_MIN_LENGTH_VALIDATION),
                Validators.maxLength(NAME_MAX_LENGTH_VALIDATION),
                Validators.pattern(NAME_PATTERN_VALIDATION),
            ],
            nonNullable: true,
        }),
        surname: new FormControl<string>("", {
            validators: [
                Validators.required,
                Validators.minLength(NAME_MIN_LENGTH_VALIDATION),
                Validators.maxLength(NAME_MAX_LENGTH_VALIDATION),
                Validators.pattern(NAME_PATTERN_VALIDATION),
            ],
            nonNullable: true,
        }),
        pilotNumber: new FormControl<string>("", {
            validators: [Validators.maxLength(PILOT_NUMBER_MAX_LENGTH)],
            nonNullable: true,
        }),
        operatorNumber: new FormControl<string>("", {
            validators: [Validators.maxLength(OPERATOR_NUMBER_MAX_LENGTH)],
            nonNullable: true,
        }),
        operatorDataCanBePublished: new FormControl<boolean>(false, {
            nonNullable: true,
        }),
    });

    protected isRequestedPhoneChange$ = this.localStore.selectByKey("isRequestedPhoneChange");
    protected phoneNumber$ = this.localStore.selectByKey("phoneNumber");
    protected isLoading$ = this.localStore.selectByKey("isLoading");
    protected isError$ = this.localStore.selectByKey("isError");
    protected isPhoneNumberVerified$ = this.store
        .select(DroneTowerMobileState.userData)
        .pipe(map((userData) => userData.isPhoneNumberVerified));
    protected hasPhoneNumberConflictError$ = this.store
        .select(DroneTowerMobileState.phoneNumberChangeRequestError)
        .pipe(map((error) => error?.status === HttpStatusCode.Conflict));
    protected userCheckin$ = this.store.select(DroneTowerMobileState.userCheckin);
    protected isPilotDataFeatureAvailable$ = this.store.select(DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.PilotData));

    constructor(private readonly localStore: LocalComponentStore<UserDataComponentState>) {
        this.localStore.setState({
            isRequestedPhoneChange: false,
            isLoading: true,
            phoneNumber: { countryCode: null, number: "" },
            isError: false,
        });
        this.store
            .dispatch(new DroneTowerMobileActions.GetPilotProfilePhoneNumber())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const profilePhoneNumberError = this.store.selectSnapshot(DroneTowerMobileState.getPilotDataError);
                if (profilePhoneNumberError) {
                    this.errorHandlingService.displayMessage({ httpErrorResponse: profilePhoneNumberError });
                    this.localStore.patchState({ isError: true, isLoading: false });
                } else {
                    this.initializeState();
                }
            });

        this.isPilotDataFeatureAvailable$.pipe(untilDestroyed(this)).subscribe((isPilotDataFeatureAvailable) => {
            if (isPilotDataFeatureAvailable) {
                this.userDataForm.controls.name.enable();
                this.userDataForm.controls.surname.enable();
            } else {
                this.userDataForm.controls.name.disable();
                this.userDataForm.controls.surname.disable();
            }
        });
    }

    protected async saveUserData() {
        if (this.userDataForm.invalid) {
            this.userDataForm.markAllAsTouched();

            return;
        }
        const userData = this.userDataForm.value;

        const isPilotDataFeatureAvailable = this.store.selectSnapshot(
            DroneTowerMobileState.isFeatureAvailable(DroneTowerFeatures.PilotData)
        );

        if (!isPilotDataFeatureAvailable) {
            await this.userDataService.patchUserData(userData).then(() => this.router.navigate(["/"]));

            return;
        }

        this.store
            .dispatch(
                new DroneTowerMobileActions.SetPilotDetails({
                    name: this.userDataForm.controls.name.value,
                    surname: this.userDataForm.controls.surname.value,
                })
            )
            .pipe(untilDestroyed(this))
            .subscribe(async () => {
                const httpErrorResponse = this.store.selectSnapshot(DroneTowerMobileState.setPilotDetailsError);
                if (httpErrorResponse) {
                    const errorMessage = this.transloco.translate("droneTowerMobileLib.userData.setPilotDetailsError");
                    this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                } else {
                    await this.userDataService.patchUserData(userData).then(() => this.router.navigate(["/"]));
                }
            });
    }

    protected closeModifyPhoneNumberPanel(): void {
        this.localStore.patchState({ isRequestedPhoneChange: false });
    }

    protected changePhoneNumber({ payload, actionType }: DataUpdateAction): void {
        this.localStore.patchState({ isLoading: true });

        if (actionType === DataChangeActionType.RequestPhoneNumberChange) {
            const payloadPhoneNumber = payload as PhoneNumber;
            this.store
                .dispatch(new DroneTowerMobileActions.RequestPhoneNumberChange(payloadPhoneNumber))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    const httpErrorResponse = this.store.selectSnapshot(DroneTowerMobileState.phoneNumberChangeRequestError);
                    if (httpErrorResponse) {
                        const errorMessage = this.transloco.translate("droneTowerMobileLib.userData.cannotRequestPhoneChange");
                        this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                    } else {
                        this.localStore.patchState({ isRequestedPhoneChange: true, phoneNumber: payloadPhoneNumber });
                    }
                    this.localStore.patchState({ isLoading: false });
                });
        } else {
            const verificationCode = payload as string;

            this.store
                .dispatch(new DroneTowerMobileActions.VerifyPhoneNumber(verificationCode))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    const httpErrorResponse = this.store.selectSnapshot(DroneTowerMobileState.phoneNumberVerificationError);
                    if (httpErrorResponse) {
                        const errorMessage = this.transloco.translate("droneTowerMobileLib.userData.verificationNumberError");
                        this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                    } else {
                        this.localStore.patchState({
                            isRequestedPhoneChange: false,
                        });

                        const message = this.transloco.translate("droneTowerMobileLib.userData.phoneNumberVerifiedMessageLabel", {
                            phoneNumber: this.formatPhoneNumberPipe.transform(this.localStore.selectSnapshotByKey("phoneNumber")),
                        });
                        this.toastService.success(message, "", { positionClass: "toast-bottom-full-width" });
                    }
                    this.localStore.patchState({ isLoading: false });
                });
        }
    }

    protected tryDisplayCheckinInProgress() {
        const userCheckin = this.store.selectSnapshot(DroneTowerMobileState.userCheckin);
        if (userCheckin) {
            const message = this.transloco.translate("droneTowerMobileLib.userData.checkInInProgress");
            this.toastService.info(message, "", { positionClass: "toast-top-full-width", closeButton: true });
        }
    }

    private initializeState() {
        const initialUserData = this.store.selectSnapshot(DroneTowerMobileState.userData);

        this.localStore.patchState({
            isRequestedPhoneChange: false,
            phoneNumber: initialUserData.phoneNumber,
            isLoading: false,
            isError: false,
        });

        this.userDataForm.setValue({
            name: initialUserData.name,
            surname: initialUserData.surname,
            pilotNumber: initialUserData.pilotNumber,
            operatorNumber: initialUserData.operatorNumber,
            operatorDataCanBePublished: initialUserData.operatorDataCanBePublished,
        });

        const userCheckin = this.store.selectSnapshot(DroneTowerMobileState.userCheckin);
        if (userCheckin) {
            this.userDataForm.disable();
        }
    }
}
