<ng-container *ngrxLet="{ collidingAirspaceElements: collidingAirspaceElements$, type: type$ } as vm">
    <dtm-ui-expandable-panel
        [hasHeaderSeparator]="false"
        [isExpanded]="!!vm.collidingAirspaceElements.length && vm.type === ZoneType.Active"
        [isDisabled]="!vm.collidingAirspaceElements.length"
        [ngClass]="{ disabled: !vm.collidingAirspaceElements.length, 'inactive-zone': vm.type === ZoneType.Inactive }"
    >
        <div class="section-header" headerSlot>
            <ng-container *ngIf="vm.collidingAirspaceElements.length; else emptyListHeader">
                {{
                    (vm.type === ZoneType.Active
                        ? "droneTowerMobileLib.locationInfo.activeZonesLabel"
                        : "droneTowerMobileLib.locationInfo.inactiveZonesLabel"
                    ) | transloco
                }}
            </ng-container>
            <ng-template #emptyListHeader>
                <span class="section-header-title">
                    {{
                        (vm.type === ZoneType.Active
                            ? "droneTowerMobileLib.locationInfo.noActiveZonesLabel"
                            : "droneTowerMobileLib.locationInfo.noInactiveZonesLabel"
                        ) | transloco
                    }}
                </span>
            </ng-template>
            <dtm-ui-icon
                name="information-fill"
                [matTooltip]="'droneTowerMobileLib.locationInfo.inactiveZoneTooltip' | transloco"
                #tooltip="matTooltip"
                (click)="toggleTooltip($event, tooltip)"
                *ngIf="vm.type === ZoneType.Inactive"
            >
            </dtm-ui-icon>
        </div>
        <section class="active-geozones-info">
            <div *ngIf="!vm.collidingAirspaceElements.length"></div>
            <button
                type="button"
                *ngFor="let collidingAirspaceElement of vm.collidingAirspaceElements"
                (click)="openDetails(collidingAirspaceElement.designator)"
                class="button-geozone"
            >
                <div>
                    <div class="geozone-details">
                        <ng-container [ngSwitch]="collidingAirspaceElement.status">
                            <dtm-ui-icon *ngSwitchCase="FlightStatus.Prohibited" name="sign-prohibition" class="error"></dtm-ui-icon>
                            <dtm-ui-icon
                                *ngSwitchCase="FlightStatus.NoRestriction"
                                name="checkbox-circle-fill"
                                class="success"
                            ></dtm-ui-icon>
                            <dtm-ui-icon
                                *ngSwitchCase="FlightStatus.NoRestrictionInfo"
                                name="error-warning-fill"
                                class="success"
                            ></dtm-ui-icon>
                            <dtm-ui-icon *ngSwitchDefault name="error-warning-fill" class="warning"></dtm-ui-icon>
                        </ng-container>
                        <span class="geozone-name">{{
                            "droneTowerMobileLib.locationInfo.designatorLabel"
                                | transloco
                                    : {
                                          geozone: collidingAirspaceElement.geozone,
                                          designator:
                                              collidingAirspaceElement.designator | alert : collidingAirspaceElement.isAlertZone | transloco
                                      }
                                | geoZone
                        }}</span>
                    </div>
                    <ng-container
                        *ngIf="
                            vm.type === ZoneType.Inactive &&
                                !collidingAirspaceElement.isStaticElement &&
                                !collidingAirspaceElement.isActive;
                            else activities
                        "
                    >
                        <div class="no-activities">{{ "droneTowerMobileLib.locationInfo.noActivitiesLabel" | transloco }}</div>
                    </ng-container>
                    <ng-template #activities>
                        <drone-tower-mobile-lib-h24 [isH24]="collidingAirspaceElement.isH24">
                            <div class="geozone-time" *ngFor="let activity of collidingAirspaceElement.plannedActivities">
                                <drone-tower-mobile-lib-geozone-date [date]="activity.startDateTime"></drone-tower-mobile-lib-geozone-date>
                                -
                                <drone-tower-mobile-lib-geozone-date [date]="activity.endDateTime"></drone-tower-mobile-lib-geozone-date>
                            </div>
                        </drone-tower-mobile-lib-h24>
                    </ng-template>
                </div>
                <dtm-ui-icon name="arrow-go"></dtm-ui-icon>
            </button>
        </section>
    </dtm-ui-expandable-panel>
</ng-container>
