import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { RouterModule } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetDirective } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { first, switchMap } from "rxjs";
import { ErrorHandlingService } from "../../../../services/error-handling-service/error-handling-service";
import { ResetPasswordActions } from "../../../../state/reset-password/reset-password.actions";
import { ResetPasswordState } from "../../../../state/reset-password/reset-password.state";
import { ResetPasswordConfirmComponent } from "../reset-password-confirm/reset-password-confirm.component";
import { ResetPasswordEmailVerificationComponent } from "../reset-password-email-verification/reset-password-email-verification.component";

interface ResetPasswordComponentState {
    currentStep: ResetPasswordSteps;
    code: string;
}
enum ResetPasswordSteps {
    Email = "Email",
    EmailVerification = "EmailVerification",
    ConfirmPassword = "ConfirmPassword",
}

@UntilDestroy()
@Component({
    standalone: true,
    imports: [
        CommonModule,
        SharedUiModule,
        SharedI18nModule,
        RouterModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        LetDirective,
        ResetPasswordEmailVerificationComponent,
        ResetPasswordConfirmComponent,
        MatInputModule,
    ],
    templateUrl: "./reset-password.component.html",
    styleUrls: ["../../shared/onboarding-layout.scss", "./reset-password.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ResetPasswordComponent {
    private readonly store = inject(Store);
    private readonly localStore = inject(LocalComponentStore<ResetPasswordComponentState>);
    private readonly translocoService = inject(TranslocoService);
    private readonly errorHandlingService = inject(ErrorHandlingService);

    protected readonly isProcessing$ = this.store.select(ResetPasswordState.isProcessing);
    protected readonly currentStep$ = this.localStore.selectByKey("currentStep");
    protected readonly code$ = this.localStore.selectByKey("code");
    protected readonly ResetPasswordSteps = ResetPasswordSteps;

    protected readonly emailFormControl = new FormControl("", {
        validators: [Validators.required, Validators.email],
        nonNullable: true,
    });

    constructor() {
        this.localStore.setState({
            currentStep: ResetPasswordSteps.Email,
            code: "",
        });
    }

    protected onSubmit() {
        if (this.emailFormControl.invalid) {
            this.emailFormControl.markAsTouched();

            return;
        }

        this.store
            .dispatch(new ResetPasswordActions.ResetPassword({ email: this.emailFormControl.value }))
            .pipe(
                switchMap(() => this.store.select(ResetPasswordState.emailError)),
                first(),
                untilDestroyed(this)
            )
            .subscribe((httpErrorResponse) => {
                if (httpErrorResponse) {
                    const errorMessage = this.translocoService.translate("droneTowerMobileLibLogin.apiErrorMessage");
                    this.errorHandlingService.displayMessage({ httpErrorResponse, errorMessage });
                } else {
                    this.redirectToStep(ResetPasswordSteps.EmailVerification);
                }
            });
    }

    protected redirectToStep(step: ResetPasswordSteps) {
        this.localStore.patchState({ currentStep: step });
    }

    protected saveValidationCode(code: string) {
        this.localStore.patchState({ currentStep: ResetPasswordSteps.ConfirmPassword, code });
    }
}
