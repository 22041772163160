<!-- TODO: DTOWER-383 Global Translation Issue-->
<ng-container
    *ngIf="
        ('droneTowerMobileLib.acknowledgeCheckinModificationDialog.headerLabel' | transloco) && translationsReady$ | ngrxPush as headerLabel
    "
>
    <h2 mat-dialog-title>{{ headerLabel }}</h2>
    <div class="content-container">
        {{ "droneTowerMobileLib.acknowledgeCheckinModificationDialog.informationText" | transloco }}
        <div class="value-container">
            {{ "droneTowerMobileLib.acknowledgeCheckinModificationDialog.currentValuesLabel" | transloco }}
            <div class="value">
                <dtm-ui-icon name="complex-top-height"></dtm-ui-icon>
                {{
                    "droneTowerMobileLib.acknowledgeCheckinModificationDialog.metersAglLabel" | transloco : { flightHeight: data.maxHeight }
                }}
            </div>
            <div class="value">
                <dtm-ui-icon name="flight-takeoff"></dtm-ui-icon>
                {{ data.startDate | localizeDate : { timeStyle: "short" } }}
            </div>
            <div class="value">
                <dtm-ui-icon name="flight-land"></dtm-ui-icon>
                {{ data.stopDate | localizeDate : { timeStyle: "short" } }}
            </div>
            <div class="additional-information">
                {{ "droneTowerMobileLib.acknowledgeCheckinModificationDialog.additionalInformationText" | transloco }}
            </div>
            <div mat-dialog-actions>
                <button [mat-dialog-close]="true" type="button" class="button-primary">
                    {{ "droneTowerMobileLib.acknowledgeCheckinModificationDialog.confirmButton" | transloco }}
                </button>
            </div>
        </div>
    </div>
</ng-container>
