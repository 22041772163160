import { ClipboardModule } from "@angular/cdk/clipboard";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { RouterLink } from "@angular/router";
import { WGS84Coordinates } from "@dtm-frontend/shared/map/leaflet";
import { ButtonTheme, ConfirmationDialogComponent, DialogService, IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { DmsCoordinatesUtils, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, MILLISECONDS_IN_MINUTE, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoModule, TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { CheckinHistory, CheckinsHistoryUserNoteChange } from "../../models/checkins.model";

interface CheckinHistoryItemComponentState {
    checkinHistoryItem: CheckinHistory | undefined;
    isUserNoteFieldVisible: boolean;
}

const MAX_NOTE_LENGTH = 200;

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-checkin-history-item[checkinHistoryItem]",
    standalone: true,
    imports: [
        CommonModule,
        IconDirective,
        SharedI18nModule,
        SharedUiModule,
        TranslocoModule,
        LetModule,
        RouterLink,
        SharedUiModule,
        SharedI18nModule,
        ClipboardModule,
        PushModule,
        MatLegacyInputModule,
        ReactiveFormsModule,
    ],
    templateUrl: "./checkin-history-item.component.html",
    styleUrls: ["./checkin-history-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CheckinHistoryItemComponent {
    @Input() public set checkinHistoryItem(value: CheckinHistory | undefined) {
        this.localStore.patchState({ checkinHistoryItem: value, isUserNoteFieldVisible: false });
        this.noteControl.reset();
    }

    @Output() public mapRedirect = new EventEmitter<WGS84Coordinates>();
    @Output() public noteDelete = new EventEmitter<string>();
    @Output() public noteUpdate = new EventEmitter<CheckinsHistoryUserNoteChange>();
    @Output() public noteAdd = new EventEmitter<CheckinsHistoryUserNoteChange>();

    protected readonly noteControl = new FormControl<string>("", {
        validators: [Validators.maxLength(MAX_NOTE_LENGTH)],
        nonNullable: true,
    });
    protected readonly GeographicCoordinatesType = GeographicCoordinatesType;
    protected readonly checkinHistoryItem$ = this.localStore.selectByKey("checkinHistoryItem").pipe(RxjsUtils.filterFalsy());
    protected readonly isUserNoteFieldVisible$ = this.localStore.selectByKey("isUserNoteFieldVisible");
    protected readonly MAX_NOTE_LENGTH = MAX_NOTE_LENGTH;

    constructor(
        private readonly localStore: LocalComponentStore<CheckinHistoryItemComponentState>,
        private readonly dialogService: DialogService,
        private readonly translocoService: TranslocoService
    ) {
        localStore.setState({ checkinHistoryItem: undefined, isUserNoteFieldVisible: false });
    }

    protected displayCoordinatesValue(coordinates: WGS84Coordinates): string {
        const latitude = DmsCoordinatesUtils.convertDmsWithDirectionToString(
            DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(coordinates.latitude, GeographicCoordinatesType.Latitude)
        );
        const longitude = DmsCoordinatesUtils.convertDmsWithDirectionToString(
            DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(
                coordinates.longitude,
                GeographicCoordinatesType.Longitude
            )
        );

        return `${latitude}, ${longitude}`;
    }

    protected showUserNoteField(checkinUserNote?: string) {
        this.localStore.patchState({ isUserNoteFieldVisible: true });
        if (checkinUserNote) {
            this.noteControl.setValue(checkinUserNote);
        } else {
            this.noteControl.reset();
        }
    }

    protected cancelNoteEdition() {
        if (this.noteControl.touched) {
            const dialogRef = this.dialogService.open(ConfirmationDialogComponent, {
                disableClose: true,

                data: {
                    confirmationText: this.translocoService.translate("droneTowerMobileLib.checkinsHistory.confirmEditionCloseText"),
                    titleText: this.translocoService.translate("droneTowerMobileLib.checkinsHistory.confirmEditionCloseTitleText"),
                    declineButtonLabel: this.translocoService.translate("droneTowerMobileLib.checkinsHistory.cancelLabel"),
                    confirmButtonLabel: this.translocoService.translate("droneTowerMobileLib.checkinsHistory.confirmEditionCloseLabel"),
                    theme: ButtonTheme.Warn,
                },
            });

            dialogRef
                .afterClosed()
                .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
                .subscribe(() => {
                    this.localStore.patchState({ isUserNoteFieldVisible: false });
                    this.noteControl.reset();
                });

            return;
        }
        this.localStore.patchState({ isUserNoteFieldVisible: false });
        this.noteControl.reset();
    }

    protected saveNote(checkin: CheckinHistory) {
        if (this.noteControl.invalid) {
            return;
        }

        if (checkin.userNote && !this.noteControl.value) {
            this.noteDelete.emit(checkin.id);

            return;
        }

        if (checkin.userNote && this.noteControl.value) {
            this.noteUpdate.emit({ checkinId: checkin.id, noteValue: this.noteControl.value });

            return;
        }

        this.noteAdd.emit({ checkinId: checkin.id, noteValue: this.noteControl.value });
    }

    protected getOverdueTime(checkin: CheckinHistory): Date {
        return new Date(checkin.endDateTime.getTime() + checkin.overdueInMinutes * MILLISECONDS_IN_MINUTE);
    }
}
