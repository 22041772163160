<ng-container [ngSwitch]="data">
    <ng-container *ngSwitchCase="CheckinDialogOptions.Checkin" [ngTemplateOutlet]="checkinTemplate"></ng-container>
    <ng-container *ngSwitchCase="CheckinDialogOptions.MissionCheckin" [ngTemplateOutlet]="checkinTemplate"></ng-container>
    <ng-container *ngSwitchCase="CheckinDialogOptions.LostControl" [ngTemplateOutlet]="lostControlTemplate"></ng-container>
    <ng-container *ngSwitchCase="CheckinDialogOptions.FinishLostControl" [ngTemplateOutlet]="finishLostControlTemplate"></ng-container>
    <ng-container *ngSwitchDefault [ngTemplateOutlet]="finishCheckinTemplate"></ng-container>
</ng-container>

<ng-template #checkinTemplate>
    <h2 mat-dialog-title>
        {{
            (data === CheckinDialogOptions.MissionCheckin
                ? "droneTowerMobileLib.checkinDialog.acceptCheckinHeaderLabel"
                : "droneTowerMobileLib.checkinDialog.checkinHeaderLabel"
            ) | transloco
        }}
    </h2>
    <div
        mat-dialog-content
        *ngrxLet="{ flightStatus: flightStatus$, flightStatusParams: flightStatusParams$ } as vm"
        dtmUiInvalidFormScrollable
    >
        <p>
            {{ "droneTowerMobileLib.checkinDialog.acceptInformationsText" | transloco }}
            <ng-container [ngSwitch]="vm.flightStatus">
                <p *ngSwitchCase="FlightStatus.Conditional" class="conditional">
                    {{ "droneTowerMobileLib.checkinDialog.conditionalZoneNote" | transloco }}
                </p>
                <p *ngSwitchCase="FlightStatus.Prohibited" class="prohibited">
                    {{ "droneTowerMobileLib.checkinDialog.prohibitedZoneNote" | transloco }}
                </p>
            </ng-container>
        </p>
        <dtm-ui-textarea-field [maxLength]="INFORMATION_MAX_LENGTH">
            <label>
                <dtm-ui-icon name="draft"></dtm-ui-icon>
                {{ "droneTowerMobileLib.checkinDialog.additionalInformationsLabel" | transloco }}
            </label>
            <textarea
                [formControl]="additionalInformationsFormControl"
                [placeholder]="'droneTowerMobileLib.checkinDialog.additionalInformationsPlaceholder' | transloco"
                matInput
            ></textarea>
            <div class="field-error" *dtmUiFieldHasError="additionalInformationsFormControl; name: 'maxlength'; error as error">
                {{ "droneTowerMobileLib.checkinDialog.additionalInformationsTooLong" | transloco : { max: error.requiredLength } }}
            </div>
        </dtm-ui-textarea-field>
        <dtm-ui-checkbox-field
            *ngIf="vm.flightStatusParams.flightHeight > ALTITUDE_WARNING_HEIGHT"
            [formControl]="altitudeConfirmationControl"
            [required]="true"
        >
            <span class="label">{{ "droneTowerMobileLib.checkinDialog.altitudeConfirmationCheckboxTitleLabel" | transloco }}</span>
        </dtm-ui-checkbox-field>
        <dtm-ui-form-errors class="preserve-error-space">
            <div class="field-error" *dtmUiFieldHasError="altitudeConfirmationControl; name: 'required'">
                {{ "droneTowerMobileLib.checkinDialog.altitudeConfirmationCheckboxRequiredError" | transloco }}
            </div>
        </dtm-ui-form-errors>
    </div>
    <div mat-dialog-actions>
        <button type="button" class="button-secondary" (click)="closeDialog()">
            {{ "droneTowerMobileLib.checkinDialog.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" (click)="submitCheckin()">
            {{ "droneTowerMobileLib.checkinDialog.confirmButtonLabel" | transloco }}
        </button>
    </div>
</ng-template>

<ng-template #lostControlTemplate>
    <h2 mat-dialog-title>{{ "droneTowerMobileLib.checkinLostControlDialog.headerLabel" | transloco }}</h2>
    <div mat-dialog-content>
        <p [innerHTML]="'droneTowerMobileLib.checkinLostControlDialog.additionalInformationText' | transloco"></p>
        <dtm-ui-input-field>
            <label>{{ "droneTowerMobileLib.checkinLostControlDialog.batteryControlLabel" | transloco }}</label>
            <div class="field-prefix">
                <dtm-ui-icon name="time"></dtm-ui-icon>
            </div>
            <input [formControl]="batteryControl" matInput type="number" />
            <div class="field-suffix">{{ "droneTowerMobileLib.checkinLostControlDialog.batterySuffix" | transloco }}</div>
            <div class="field-error" *dtmUiFieldHasError="batteryControl; name: 'min'; error as error">
                {{ "droneTowerMobileLib.checkinLostControlDialog.minValueError" | transloco : { value: error.min } }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="batteryControl; name: 'required'">
                {{ "droneTowerMobileLib.checkinLostControlDialog.requiredError" | transloco }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-input-field>
            <label>{{ "droneTowerMobileLib.checkinLostControlDialog.speedControlLabel" | transloco }}</label>
            <div class="field-prefix">
                <dtm-ui-icon name="speed-up-fill"></dtm-ui-icon>
            </div>
            <input [formControl]="speedControl" matInput type="number" />
            <div class="field-suffix">{{ "droneTowerMobileLib.checkinLostControlDialog.speedSuffix" | transloco }}</div>
            <div class="field-error" *dtmUiFieldHasError="speedControl; name: 'min'; error as error">
                {{ "droneTowerMobileLib.checkinLostControlDialog.minValueError" | transloco : { value: error.min } }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="speedControl; name: 'required'">
                {{ "droneTowerMobileLib.checkinLostControlDialog.requiredError" | transloco }}
            </div>
        </dtm-ui-input-field>
    </div>
    <div mat-dialog-actions>
        <button (click)="closeDialog()" type="button" class="button-secondary">
            {{ "droneTowerMobileLib.checkinLostControlDialog.cancelButtonLabel" | transloco }}
        </button>
        <button (click)="submitLostControl()" type="button" class="button-primary">
            {{ "droneTowerMobileLib.checkinLostControlDialog.confirmButtonLabel" | transloco }}
        </button>
    </div>
</ng-template>

<ng-template #finishLostControlTemplate>
    <h2 mat-dialog-title>{{ "droneTowerMobileLib.checkinEndDialog.headerLabel" | transloco }}</h2>
    <div mat-dialog-content>
        <p [innerHTML]="'droneTowerMobileLib.checkinEndDialog.lostControlAdditionalInformationText' | transloco"></p>
    </div>
    <div mat-dialog-actions>
        <button (click)="closeDialog()" type="button" class="button-secondary">
            {{ "droneTowerMobileLib.checkinEndDialog.cancelLabel" | transloco }}
        </button>
        <button (click)="submitFinishCheckin()" type="button" class="button-primary">
            {{ "droneTowerMobileLib.checkinEndDialog.confirmLabel" | transloco }}
        </button>
    </div>
</ng-template>

<ng-template #finishCheckinTemplate>
    <h2 mat-dialog-title>{{ "droneTowerMobileLib.checkinEndDialog.headerLabel" | transloco }}</h2>
    <div mat-dialog-content *ngIf="(userCheckin$ | ngrxPush)?.properties?.isFromMissionPlanner">
        <dtm-ui-checkbox-field [formControl]="shouldFinishMissionFromMissionPlannerControl">
            <span class="label">{{ "droneTowerMobileLib.checkinEndDialog.missionPlannerCheckboxTitleLabel" | transloco }}</span>
            <div class="hint">{{ "droneTowerMobileLib.checkinEndDialog.missionPlannerCheckboxSubtitleLabel" | transloco }}</div>
        </dtm-ui-checkbox-field>
    </div>
    <div mat-dialog-actions>
        <button (click)="closeDialog()" type="button" class="button-secondary">
            {{ "droneTowerMobileLib.checkinEndDialog.cancelLabel" | transloco }}
        </button>
        <button (click)="submitFinishCheckin()" type="button" class="button-primary">
            {{ "droneTowerMobileLib.checkinEndDialog.confirmLabel" | transloco }}
        </button>
    </div>
</ng-template>
