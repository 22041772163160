import { Directive, ElementRef, Input } from "@angular/core";
import { TooltipOptions } from "leaflet";

@Directive({
    selector: "[dtmMapLeafletTooltipContent]",
})
export class LeafletTooltipContentDirective {
    @Input() public tooltipOptions: TooltipOptions = {};

    public get nativeElement(): HTMLElement {
        return this.element.nativeElement;
    }

    constructor(private readonly element: ElementRef) {}
}
