import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
    AirspaceComponent,
    AuthGuard,
    CheckInOperatorComponent,
    CheckinsHistoryComponent,
    CheckinsHistoryModule,
    FiltersModalGuard,
    HelpComponent,
    InfoComponent,
    IntroWalkthroughComponent,
    OperatorsListComponent,
    RulesComponent,
    UpsertOperatorComponent,
} from "@dtm-frontend/drone-tower-mobile-lib";
import { LoginRoutingModule } from "@dtm-frontend/drone-tower-mobile-lib/login";
import { MapRoutingModule } from "@dtm-frontend/drone-tower-mobile-lib/map";

const routes: Routes = [
    {
        path: "info",
        component: InfoComponent,
    },
    {
        path: "airspace",
        component: AirspaceComponent,
    },
    {
        path: "checkins-history",
        component: CheckinsHistoryComponent,
        canActivate: [AuthGuard],
        canDeactivate: [FiltersModalGuard],
    },
    {
        path: "operators",
        component: OperatorsListComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "upsert-operator",
        component: UpsertOperatorComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "upsert-operator/:operatorName",
        component: UpsertOperatorComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "check-in-operator",
        component: CheckInOperatorComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "rules",
        component: RulesComponent,
    },
    {
        path: "intro",
        component: IntroWalkthroughComponent,
    },
    {
        path: "help",
        component: HelpComponent,
    },
    {
        path: "**",
        redirectTo: "map",
    },
];

@NgModule({
    imports: [LoginRoutingModule, MapRoutingModule, CheckinsHistoryModule, RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
