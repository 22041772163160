import { DmsCoordinatesUtils, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { Feature, FeatureCollection, Geometry } from "@turf/helpers";
import { ActiveCheckinProperties, ActiveCheckinStatus } from "../../models/checkins.model";
import { ActiveCheckin, ActiveCheckinResponseBody, ActiveCheckinsResponseBody } from "./checkins-api.model";

export interface LostControlRequestPayload {
    estimatedBatteryLifetime: number;
    velocity: number;
}

function isActiveCheckinStatusRequiresAcknowledge(status: ActiveCheckinStatus) {
    return (
        status === ActiveCheckinStatus.Accepted ||
        status === ActiveCheckinStatus.AtcModified ||
        status === ActiveCheckinStatus.LandNow ||
        status === ActiveCheckinStatus.Rejected
    );
}

export function convertActiveCheckinResponseBodyToActiveCheckin(activeCheckinResponseBody: ActiveCheckinResponseBody): ActiveCheckin {
    return {
        id: activeCheckinResponseBody.id,
        startDateTime: activeCheckinResponseBody.startDateTime,
        endDateTime: activeCheckinResponseBody.endDateTime,
        flightArea: activeCheckinResponseBody.flightArea,
        status: activeCheckinResponseBody.status,
        pilotPhoneNumber: activeCheckinResponseBody.pilotPhoneNumber,
        isSupervision: activeCheckinResponseBody.supervision,
        missionArea: activeCheckinResponseBody.missionArea,
        isFromMissionPlanner: activeCheckinResponseBody.fromMissionPlanner,
    };
}

export function convertActiveCheckinResponseBodyToFeature(
    activeCheckinResponseBody: ActiveCheckinResponseBody
): Feature<Geometry, ActiveCheckinProperties> {
    const activeCheckin = convertActiveCheckinResponseBodyToActiveCheckin(activeCheckinResponseBody);

    return {
        type: "Feature",
        id: activeCheckin.id,
        geometry: {
            type: "Point",
            coordinates: [activeCheckin.flightArea.center.longitude, activeCheckin.flightArea.center.latitude],
        },
        properties: {
            id: activeCheckin.id,
            status: activeCheckin.status.toLowerCase() as ActiveCheckinStatus,
            startDate: new Date(activeCheckin.startDateTime),
            stopDate: new Date(activeCheckin.endDateTime),
            maxHeight: activeCheckin.flightArea.maxHeight,
            radius: activeCheckin.flightArea.radius,
            latitudeDegreesMinutesSeconds: DmsCoordinatesUtils.convertDmsWithDirectionToString(
                DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(
                    activeCheckin.flightArea.center.latitude,
                    GeographicCoordinatesType.Latitude
                )
            ),
            latitude: activeCheckin.flightArea.center.latitude,
            longitudeDegreesMinutesSeconds: DmsCoordinatesUtils.convertDmsWithDirectionToString(
                DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(
                    activeCheckin.flightArea.center.longitude,
                    GeographicCoordinatesType.Longitude
                )
            ),
            longitude: activeCheckin.flightArea.center.longitude,
            phoneNumber: activeCheckin.pilotPhoneNumber,
            missionArea: activeCheckin.missionArea,
            isAcknowledgeRequired: isActiveCheckinStatusRequiresAcknowledge(activeCheckin.status.toLowerCase() as ActiveCheckinStatus),
            isFromMissionPlanner: activeCheckin.isFromMissionPlanner,
        },
    };
}

export function convertActiveCheckinsResponseBodyToFeatureCollection(
    activeCheckinsResponseBody: ActiveCheckinsResponseBody
): FeatureCollection<Geometry, ActiveCheckinProperties> {
    const featureCollection: FeatureCollection<Geometry, ActiveCheckinProperties> = {
        type: "FeatureCollection",
        features: [],
    };

    const allCheckins = activeCheckinsResponseBody.checkins;
    if (activeCheckinsResponseBody.userCheckin) {
        allCheckins.push(activeCheckinsResponseBody.userCheckin);
    }
    const features = allCheckins.map((activeCheckin) => convertActiveCheckinResponseBodyToFeature(activeCheckin));
    featureCollection.features = [...features];

    return featureCollection;
}
