<ng-container
    *ngrxLet="{
        isCheckInOpen: isCheckInOpen$,
        userCheckin: userCheckin$,
        isCreateCheckinLoading: isCreateCheckinLoading$,
        activeCheckins: activeCheckins$,
        areOtherCheckinsVisible: areOtherCheckinsVisible$,
        areActiveMissionAreasVisible: areActiveMissionAreasVisible$,
        tilesetStyle: tilesetStyle$
    } as vm"
>
    <dtm-map-leaflet-map [showZoomButtons]="false" [mapOptions]="mapOptions" [tilesetStyle]="vm.tilesetStyle">
        <drone-tower-mobile-lib-user-position-marker
            [isCheckinOpen]="vm.isCheckInOpen"
            [isOnGPSPosition]="isOnGPSPosition$ | ngrxPush"
            [mapRedirectPosition]="mapRedirectPosition$ | ngrxPush"
            (markerPositionChange)="onMarkerChange($event)"
        ></drone-tower-mobile-lib-user-position-marker>
        <dtm-map-leaflet-map-layer-smooth-transition
            [layerConfig]="geoserverLayerConfig$ | ngrxPush"
        ></dtm-map-leaflet-map-layer-smooth-transition>
        <drone-tower-mobile-lib-active-checkins
            [userCheckin]="vm.userCheckin"
            [activeCheckins]="vm.activeCheckins"
            [areOtherCheckinsVisible]="vm.areOtherCheckinsVisible"
            [areActiveMissionAreasVisible]="vm.areActiveMissionAreasVisible"
        ></drone-tower-mobile-lib-active-checkins>
        <drone-tower-mobile-lib-mission-area
            [isCheckinOpen]="vm.isCheckInOpen"
            [missionGeometry]="missionGeometry$ | ngrxPush"
        ></drone-tower-mobile-lib-mission-area>
        <drone-tower-mobile-lib-zoom (zoomChange)="onZoomChange($event)"></drone-tower-mobile-lib-zoom>
    </dtm-map-leaflet-map>
    <drone-tower-mobile-lib-kp-index></drone-tower-mobile-lib-kp-index>
    <drone-tower-mobile-lib-floating-menu
        [isCheckinActive]="!!vm.userCheckin"
        (menuOptionActionClicked)="menuOptionActionClickedHandler($event)"
        [isFloatingMenuHidden]="vm.isCheckInOpen"
        [isLoading]="vm.isCreateCheckinLoading"
    ></drone-tower-mobile-lib-floating-menu>
    <drone-tower-mobile-lib-check-in
        *ngIf="vm.isCheckInOpen"
        (checkInClose)="closeCheckinPanel()"
        @slideIn
    ></drone-tower-mobile-lib-check-in>
    <div class="map-buttons" *ngIf="!vm.isCheckInOpen">
        <button type="button" class="button-icon map-button" (click)="goToGPSPosition()">
            <dtm-ui-icon slot="start" name="focus-2"></dtm-ui-icon>
        </button>
        <button type="button" class="button-icon map-button" routerLink="/airspace" *ngIf="isAirspaceFeatureAvailable$ | ngrxPush">
            <dtm-ui-icon name="stack-line"></dtm-ui-icon>
        </button>
    </div>
</ng-container>
