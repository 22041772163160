<drone-tower-mobile-lib-header
    [headerLabel]="'droneTowerMobileLib.flightStatusForm.headerLabel' | transloco"
></drone-tower-mobile-lib-header>
<div class="form-container">
    <form
        [formGroup]="statusForm"
        *ngrxLet="{
            openedPanel: openedPanel$,
            isMissionSelected: isMissionSelected$,
        } as vm"
    >
        <dtm-ui-expandable-panel [isExpanded]="vm.openedPanel === FlightConditionCategory.UAVCategory" [hasHeaderSeparator]="false">
            <dtm-ui-expandable-simple-header
                headerSlot
                [label]="'droneTowerMobileLib.flightStatusForm.uavInfoLabel' | transloco"
            ></dtm-ui-expandable-simple-header>
            <div class="section-container">
                <label>
                    <dtm-ui-icon name="drone"></dtm-ui-icon>
                    {{ "droneTowerMobileLib.flightStatusForm.uavWeightLabel" | transloco }}
                </label>
                <div class="grid-options-container">
                    <div
                        class="selectable-tile"
                        [class.selected]="weightRangeControl.value === Weight.Light"
                        (click)="changeSelectedWeightOptions(Weight.Light)"
                    >
                        <div class="selectable-tile-wrapper">
                            <h2 class="selectable-tile-label">{{ "droneTowerMobileLib.weightLabels.lightLabel" | transloco }}</h2>
                        </div>
                    </div>
                    <div
                        class="selectable-tile"
                        [class.selected]="weightRangeControl.value === Weight.MediumLight"
                        (click)="changeSelectedWeightOptions(Weight.MediumLight)"
                    >
                        <div class="selectable-tile-wrapper">
                            <h2 class="selectable-tile-label">{{ "droneTowerMobileLib.weightLabels.mediumLightLabel" | transloco }}</h2>
                        </div>
                    </div>
                    <div
                        class="selectable-tile"
                        [class.selected]="weightRangeControl.value === Weight.Medium"
                        (click)="changeSelectedWeightOptions(Weight.Medium)"
                    >
                        <div class="selectable-tile-wrapper">
                            <h2 class="selectable-tile-label">{{ "droneTowerMobileLib.weightLabels.mediumLabel" | transloco }}</h2>
                        </div>
                    </div>
                    <div
                        class="selectable-tile"
                        [class.selected]="weightRangeControl.value === Weight.Heavy"
                        (click)="changeSelectedWeightOptions(Weight.Heavy)"
                    >
                        <div class="selectable-tile-wrapper">
                            <h2 class="selectable-tile-label">{{ "droneTowerMobileLib.weightLabels.heavyLabel" | transloco }}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-container" [class.disabled]="vm.isMissionSelected">
                <label>
                    <dtm-ui-icon name="draft"></dtm-ui-icon>
                    {{ "droneTowerMobileLib.flightStatusForm.categoryLabel" | transloco }}
                </label>
                <div class="grid-options-container">
                    <div
                        *ngFor="let category of capabilities.categories"
                        class="selectable-tile"
                        [class.selected]="categoryControl.value === category.category"
                        [class.disabled]="weightRangeControl.value === Weight.Heavy"
                        (click)="changeSelectedCategoryOptions(category)"
                    >
                        <div class="selectable-tile-wrapper">
                            <h2 class="selectable-tile-label">{{ category.name | systemTranslation }}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-container" [class.disabled]="vm.isMissionSelected">
                <label>
                    <dtm-ui-icon name="file-list"></dtm-ui-icon>
                    {{ "droneTowerMobileLib.flightStatusForm.subCategoryLabel" | transloco }}
                </label>
                <div class="options-container">
                    <div
                        *ngFor="let subcategory of capabilities.subcategories[this.categoryControl.value]"
                        class="selectable-tile"
                        [class.selected]="subcategoryControl.value === subcategory.subcategory"
                        [class.disabled]="weightRangeControl.value > subcategory.maxWeight"
                        (click)="changeSelectedSubCategoryOptions(subcategory.subcategory, subcategory)"
                    >
                        <div class="selectable-tile-wrapper">
                            <h2 class="selectable-tile-label">{{ subcategory.name | systemTranslation }}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </dtm-ui-expandable-panel>
        <dtm-ui-expandable-panel [isExpanded]="vm.openedPanel === FlightConditionCategory.FlightAreaCategory" [hasHeaderSeparator]="false">
            <dtm-ui-expandable-simple-header
                headerSlot
                [label]="'droneTowerMobileLib.flightStatusForm.flightAreaLabel' | transloco"
            ></dtm-ui-expandable-simple-header>
            <div class="section-container" *ngrxLet="{ maxAltitude: maxAltitude$, maxMissionAltitude: maxMissionAltitude$ } as vm">
                <label>
                    <dtm-ui-icon name="complex-top-height"></dtm-ui-icon>
                    {{ "droneTowerMobileLib.flightStatusForm.maxHeightLabel" | transloco }}
                </label>
                <dtm-ui-expanded-number-input
                    [formControl]="heightRangeControl"
                    [valueSuffix]="'droneTowerMobileLib.flightStatusForm.units.metersAgl' | transloco"
                    [minValue]="MIN_ALTITUDE_VALUE"
                    [maxValue]="vm.maxAltitude"
                    [predefinedOptions]="PREDEFINED_HEIGHT_OPTIONS"
                    [class.altitude-warning-highlight]="heightRangeControl.value > ALTITUDE_WARNING_HEIGHT"
                >
                    <div class="field-error" *dtmUiFieldHasError="heightRangeControl; name: 'min'; error as error">
                        {{ "droneTowerMobileLib.flightStatusForm.minHeightValueErrorMessage" | transloco : { meters: error.min } }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="heightRangeControl; name: 'max'; error as error">
                        {{
                            ((vm.maxMissionAltitude ? vm.maxMissionAltitude <= vm.maxAltitude : false)
                                ? "droneTowerMobileLib.flightStatusForm.maxMissionHeightErrorMessage"
                                : "droneTowerMobileLib.flightStatusForm.maxHeightErrorMessage"
                            ) | transloco : { meters: error.max | localizeNumber }
                        }}
                    </div>
                </dtm-ui-expanded-number-input>
                <div class="field-warning" *ngIf="heightRangeControl.value > ALTITUDE_WARNING_HEIGHT">
                    <div class="warning-icon">
                        <dtm-ui-icon name="error-warning"></dtm-ui-icon>
                    </div>
                    <div class="warning-text">
                        {{
                            "droneTowerMobileLib.flightStatusForm.altitudeWarningHeightLabel"
                                | transloco : { meters: ALTITUDE_WARNING_HEIGHT | localizeNumber }
                        }}
                    </div>
                </div>
            </div>
            <div class="section-container">
                <label>
                    <dtm-ui-icon name="complex-radius"></dtm-ui-icon>
                    {{ "droneTowerMobileLib.flightStatusForm.radiusLabel" | transloco }}
                </label>
                <dtm-ui-expanded-number-input
                    [formControl]="radiusControl"
                    [valueSuffix]="'droneTowerMobileLib.flightStatusForm.units.meters' | transloco"
                    [minValue]="MIN_RADIUS_VALUE"
                    [maxValue]="MAX_RADIUS_VALUE"
                    [predefinedOptions]="PREDEFINED_RADIUS_OPTIONS"
                >
                    <div class="field-error" *dtmUiFieldHasError="radiusControl; name: 'min'; error as error">
                        {{ "droneTowerMobileLib.flightStatusForm.minRadiusValueErrorMessage" | transloco : { meters: error.min } }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="radiusControl; name: 'max'; error as error">
                        {{ "droneTowerMobileLib.flightStatusForm.maxRadiusValueErrorMessage" | transloco : { meters: error.max } }}
                    </div>
                </dtm-ui-expanded-number-input>
            </div>
        </dtm-ui-expandable-panel>
        <dtm-ui-expandable-panel [isExpanded]="vm.openedPanel === FlightConditionCategory.TimeCategory" [hasHeaderSeparator]="false">
            <dtm-ui-expandable-simple-header
                headerSlot
                [label]="'droneTowerMobileLib.flightStatusForm.timeIntervalLabel' | transloco"
            ></dtm-ui-expandable-simple-header>
            <div class="section-container">
                <label>
                    <dtm-ui-icon name="flight-takeoff"></dtm-ui-icon>
                    {{ "droneTowerMobileLib.flightStatusForm.startTimeLabel" | transloco }}
                </label>
                <dtm-ui-expanded-number-input
                    [formControl]="startTimeControl"
                    [valueSuffix]="'droneTowerMobileLib.flightStatusForm.units.minutes' | transloco"
                    [minValue]="MIN_MINUTES_VALUE"
                    [maxValue]="MAX_MINUTES_VALUE"
                    [predefinedOptions]="PREDEFINED_START_TIME_OPTIONS"
                >
                    <div class="field-error" *dtmUiFieldHasError="startTimeControl; name: 'min'; error as error">
                        {{ "droneTowerMobileLib.flightStatusForm.minMinutesValueErrorMessage" | transloco : { minutes: error.min } }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="startTimeControl; name: 'max'; error as error">
                        {{ "droneTowerMobileLib.flightStatusForm.maxMinutesValueErrorMessage" | transloco : { minutes: error.max } }}
                    </div>
                </dtm-ui-expanded-number-input>
            </div>
            <div class="section-container">
                <label>
                    <dtm-ui-icon name="time"></dtm-ui-icon>
                    {{ "droneTowerMobileLib.flightStatusForm.durationLabel" | transloco }}
                </label>
                <dtm-ui-expanded-number-input
                    [formControl]="durationControl"
                    [valueSuffix]="'droneTowerMobileLib.flightStatusForm.units.minutes' | transloco"
                    [minValue]="MIN_DURATION_VALUE"
                    [maxValue]="MAX_DURATION_VALUE"
                    [predefinedOptions]="PREDEFINED_DURATION_OPTIONS"
                >
                    <div class="field-error" *dtmUiFieldHasError="durationControl; name: 'min'; error as error">
                        {{ "droneTowerMobileLib.flightStatusForm.minMinutesValueErrorMessage" | transloco : { minutes: error.min } }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="durationControl; name: 'max'; error as error">
                        {{ "droneTowerMobileLib.flightStatusForm.maxMinutesValueErrorMessage" | transloco : { minutes: error.max } }}
                    </div>
                </dtm-ui-expanded-number-input>
            </div>
        </dtm-ui-expandable-panel>
    </form>
</div>
<section class="buttons-section">
    <button type="button" class="button-secondary" routerLink="/">
        {{ "droneTowerMobileLib.flightStatusForm.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" (click)="saveDetails()" [disabled]="isButtonDisabled$ | ngrxPush">
        {{ "droneTowerMobileLib.flightStatusForm.saveButtonLabel" | transloco }}
    </button>
</section>
