<ng-container
    *ngrxLet="{
        pilotOperators: pilotOperators$,
        pilotOperatorsError: pilotOperatorsError$,
        isProcessing: isProcessing$,
    } as vm"
>
    <drone-tower-mobile-lib-header
        [headerLabel]="'droneTowerMobileLib.operators.operatorsListLabel' | transloco"
    ></drone-tower-mobile-lib-header>
    <div class="wrapper scroll-shadows">
        <dtm-ui-loader-container [isShown]="vm.isProcessing">
            <ng-container *ngIf="vm.pilotOperators?.length; else emptyStateTemplate">
                <ul class="list" *ngIf="!vm.pilotOperatorsError; else errorTemplate">
                    <li class="row" *ngFor="let pilotOperator of vm.pilotOperators">
                        <div class="operator">
                            <div>
                                <button type="button" class="button-icon" (click)="toggleFavoriteOperator(pilotOperator)">
                                    <dtm-ui-icon
                                        name="star-fill"
                                        class="star"
                                        [ngClass]="{ 'star-favorite': pilotOperator.isFavorite }"
                                    ></dtm-ui-icon>
                                </button>
                            </div>
                            <div class="operator-details">
                                <h2 class="name">{{ pilotOperator.name }}</h2>
                                <p>{{ pilotOperator.number }}</p>
                            </div>
                        </div>
                        <div class="actions">
                            <button type="button" class="button-icon" [routerLink]="['/upsert-operator', pilotOperator.name]">
                                <dtm-ui-icon name="edit"></dtm-ui-icon>
                            </button>
                            <button type="button" class="button-icon" (click)="deleteOperator(pilotOperator.name)">
                                <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                            </button>
                        </div>
                    </li>
                </ul>
            </ng-container>
            <ng-template #emptyStateTemplate>
                <dtm-ui-no-results></dtm-ui-no-results>
            </ng-template>
        </dtm-ui-loader-container>
    </div>
    <ng-template #errorTemplate>
        <div class="wrapper-error">{{ "droneTowerMobileLib.operators.getPilotOperatorsError" | transloco }}</div>
    </ng-template>
    <div class="action-wrapper" *ngIf="!vm.pilotOperatorsError">
        <button type="button" class="button-primary" routerLink="/upsert-operator">
            {{ "droneTowerMobileLib.operators.addOperatorLabel" | transloco }}
        </button>
    </div>
</ng-container>
