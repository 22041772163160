export const DRONE_TOWER_USER_GUIDE_URL = "https://assets.uav.pansa.pl/DroneTower-podrecznik-uzytkownika.pdf";

export const REDIRECT_PARAM = "redirect";
export const REDIRECT_REGISTRATION = "registration";
export const REDIRECT_RESET_PASSWORD = "reset-password";

export const ERROR_MESSAGE_CHECKIN_NOT_FOUND = "drone-tower.checkin.checkin-not-found";
export const ERROR_MESSAGE_PHONE_NUMBER_ALREADY_REGISTERED = "drone-tower.phone-number.already-registered";
export const ERROR_MESSAGE_EMAIL_ALREADY_REGISTERED = "drone-tower.email.already-registered";
export const ERROR_MESSAGE_PILOT_DATA_INCOMPLETE = "drone-tower.pansa-utm.pilot-data-incomplete";
export const ERROR_MESSAGE_OPERATOR_NUMBER_ALREADY_EXISTS = "drone-tower.operator-number.already-exists";
export const ERROR_MESSAGE_OPERATOR_NAME_ALREADY_EXISTS = "drone-tower.operator-name.already-exists";

export const PANSA_UTM_PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\\-_=+]).{8,}$/;
export const NAME_PATTERN_VALIDATION = /^[\p{L}]+([-.' ][\p{L}]+)*$/u;
export const NAME_MIN_LENGTH_VALIDATION = 2;
export const NAME_MAX_LENGTH_VALIDATION = 64;

export const CHECKIN_OPERATOR_NUMBER_LENGTH = 50;

export const ALTITUDE_WARNING_HEIGHT = 120;
