import { Directive, EventEmitter, inject, OnInit, Output } from "@angular/core";
import { LEAFLET_MAP_PROVIDER } from "@dtm-frontend/shared/map/leaflet";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";

@UntilDestroy()
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "drone-tower-mobile-lib-zoom",
    standalone: true,
    providers: [LocalComponentStore],
})
export class ZoomDirective implements OnInit {
    private readonly mapProvider = inject(LEAFLET_MAP_PROVIDER);

    @Output() private readonly zoomChange = new EventEmitter<number>();

    public ngOnInit(): void {
        this.initZoom();
    }

    private async initZoom() {
        const map = await this.mapProvider.getMap();

        map.addEventListener("zoomend", () => this.zoomChange.emit(map.getZoom()));
    }
}
