import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { IonicModule } from "@ionic/angular";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { LoginComponent } from "./components/login/login.component";
import { LoginRoutingModule } from "./login-routing.module";

@NgModule({
    imports: [
        LoginRoutingModule,
        BrowserAnimationsModule,
        SharedI18nModule,
        IonicModule,
        SharedUiModule,
        ReactiveFormsModule,
        PushModule,
        LetModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatInputModule,
    ],
    declarations: [LoginComponent],
    exports: [LoginComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "droneTowerMobileLibLogin",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class LoginModule {}
