import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, inject } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoModule } from "@jsverse/transloco";
import { LetDirective, PushPipe } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { FlightStatus } from "../../models/flight-conditions.model";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";

export enum CheckinDialogOptions {
    Checkin = "checkin",
    MissionCheckin = "mission-checkin",
    LostControl = "lost_control",
    FinishLostControl = "finish_lost_control",
}
const INFORMATION_MAX_LENGTH = 200;
const DEFAULT_CONTROL_MIN_VALUE = 0;

@Component({
    selector: "drone-tower-mobile-lib-checkin-dialog",
    standalone: true,
    imports: [
        CommonModule,
        MatDialogModule,
        MatInputModule,
        SharedI18nModule,
        SharedUiModule,
        TranslocoModule,
        ReactiveFormsModule,
        MatLegacyDialogModule,
        PushPipe,
        LetDirective,
    ],
    templateUrl: "./checkin-dialog.component.html",
    styleUrls: ["./checkin-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckinDialogComponent {
    private readonly store = inject(Store);

    protected readonly FlightStatus = FlightStatus;
    protected readonly flightStatus$ = this.store.select(DroneTowerMobileState.flightStatus);
    protected readonly batteryControl = new FormControl<number>(DEFAULT_CONTROL_MIN_VALUE, [
        Validators.required,
        Validators.min(DEFAULT_CONTROL_MIN_VALUE),
    ]);
    protected readonly speedControl = new FormControl<number>(DEFAULT_CONTROL_MIN_VALUE, [
        Validators.required,
        Validators.min(DEFAULT_CONTROL_MIN_VALUE),
    ]);
    protected readonly shouldFinishMissionFromMissionPlannerControl = new FormControl<boolean>(false);
    protected readonly additionalInformationsFormControl = new FormControl("", Validators.maxLength(INFORMATION_MAX_LENGTH));
    protected readonly CheckinDialogOptions = CheckinDialogOptions;
    protected readonly INFORMATION_MAX_LENGTH = INFORMATION_MAX_LENGTH;
    protected readonly userCheckin$ = this.store.select(DroneTowerMobileState.userCheckin);

    constructor(
        protected readonly dialogRef: MatDialogRef<CheckinDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public readonly data: CheckinDialogOptions | null
    ) {}

    protected submitLostControl() {
        this.speedControl.updateValueAndValidity();
        this.batteryControl.updateValueAndValidity();
        if (this.speedControl.valid && this.batteryControl.valid) {
            this.dialogRef.close({ battery: this.batteryControl.value, speed: this.speedControl.value });
        }
    }

    protected submitCheckin() {
        this.additionalInformationsFormControl.updateValueAndValidity();
        if (this.additionalInformationsFormControl.valid) {
            this.dialogRef.close(this.additionalInformationsFormControl.value);
        }
    }

    protected submitFinishCheckin() {
        const shouldFinishMissionFromMissionPlanner = this.shouldFinishMissionFromMissionPlannerControl.value;
        this.dialogRef.close({ isFinished: true, shouldFinishMissionFromMissionPlanner });
    }

    protected closeDialog() {
        this.dialogRef.close(false);
    }
}
