import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { IconDirective, SharedUiModule } from "@dtm-frontend/shared/ui";
import { SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LetModule, PushModule } from "@ngrx/component";
import { Store } from "@ngxs/store";
import { map, tap } from "rxjs";
import { FlightConditionCategory, OPENED_FLIGHT_CATEGORY_QUERY_PARAM_NAME } from "../../models/flight-conditions.model";
import { HeaderComponent } from "../../shared/components/header/header.component";
import { DroneTowerMobileActions } from "../../state/drone-tower-mobile.actions";
import { DroneTowerMobileState } from "../../state/drone-tower-mobile.state";
import {
    FlightCategoriesTranslationKeysValues,
    FlightCategoriesValues,
    FlightCategory,
    FlightStatusParams,
    Weight,
} from "../../state/drone-tower-mobile.state.model";
import { ALTITUDE_WARNING_HEIGHT } from "../../utils/defaults";
import { FlightSubcategory } from "./../../models/flight-conditions.model";

interface StatusFormGroup {
    flightCategory: FormControl<FlightCategoriesValues>;
    flightSubcategory: FormControl<string>;
    flightCategoryTranslationKey: FormControl<string>;
    flightSubcategoryTranslationKey: FormControl<string>;
    uavWeight: FormControl<Weight>;
    flightHeight: FormControl<number>;
    radius: FormControl<number>;
    startTime: FormControl<number>;
    duration: FormControl<number>;
}

interface FlightStatusFormComponentState {
    maxAltitude: number;
    maxMissionAltitude?: number;
    openedPanel: FlightConditionCategory;
}

/* eslint-disable no-magic-numbers */
const PREDEFINED_HEIGHT_OPTIONS = [30, 50, 70, 100];
const PREDEFINED_RADIUS_OPTIONS = [50, 100, 200, 300, 500];
const PREDEFINED_START_TIME_OPTIONS = [0, 5, 10];
const PREDEFINED_DURATION_OPTIONS = [20, 30, 60, 90, 120];
/* eslint-enable */
const MAX_ALTITUDE_VALUE = 10000;
const MIN_ALTITUDE_VALUE = 5;
const MIN_RADIUS_VALUE = 5;
const MAX_RADIUS_VALUE = 500;
const MIN_MINUTES_VALUE = 0;
const MAX_MINUTES_VALUE = 15;
const MIN_DURATION_VALUE = 5;
const MAX_DURATION_VALUE = 180;

@UntilDestroy()
@Component({
    selector: "drone-tower-mobile-lib-flight-status-form",
    standalone: true,
    imports: [
        CommonModule,
        IconDirective,
        SharedUiModule,
        SharedI18nModule,
        ReactiveFormsModule,
        RouterLink,
        LetModule,
        PushModule,
        HeaderComponent,
    ],
    providers: [LocalComponentStore],
    templateUrl: "./flight-status-form.component.html",
    styleUrls: ["./flight-status-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightStatusFormComponent implements OnInit {
    private readonly store = inject(Store);
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly localStore: LocalComponentStore<FlightStatusFormComponentState> = inject(
        LocalComponentStore<FlightStatusFormComponentState>
    );
    private readonly flightStatusParams = this.store.selectSnapshot(DroneTowerMobileState.flightStatusParams);
    protected readonly capabilities = this.store.selectSnapshot(DroneTowerMobileState.capabilities);
    protected readonly FlightCategoriesValues = FlightCategoriesValues;
    protected readonly PREDEFINED_HEIGHT_OPTIONS = PREDEFINED_HEIGHT_OPTIONS;
    protected readonly PREDEFINED_RADIUS_OPTIONS = PREDEFINED_RADIUS_OPTIONS;
    protected readonly PREDEFINED_START_TIME_OPTIONS = PREDEFINED_START_TIME_OPTIONS;
    protected readonly PREDEFINED_DURATION_OPTIONS = PREDEFINED_DURATION_OPTIONS;
    protected readonly maxAltitude$ = this.localStore.selectByKey("maxAltitude");
    protected readonly maxMissionAltitude$ = this.localStore.selectByKey("maxMissionAltitude");
    protected readonly openedPanel$ = this.localStore.selectByKey("openedPanel");
    protected readonly ALTITUDE_WARNING_HEIGHT = ALTITUDE_WARNING_HEIGHT;
    protected readonly MIN_ALTITUDE_VALUE = MIN_ALTITUDE_VALUE;
    protected readonly MIN_RADIUS_VALUE = MIN_RADIUS_VALUE;
    protected readonly MAX_RADIUS_VALUE = MAX_RADIUS_VALUE;
    protected readonly MIN_DURATION_VALUE = MIN_DURATION_VALUE;
    protected readonly MAX_DURATION_VALUE = MAX_DURATION_VALUE;
    protected readonly MIN_MINUTES_VALUE = MIN_MINUTES_VALUE;
    protected readonly MAX_MINUTES_VALUE = MAX_MINUTES_VALUE;
    protected readonly Weight = Weight;
    protected readonly FlightConditionCategory = FlightConditionCategory;

    protected readonly isMissionSelected$ = this.store.select(DroneTowerMobileState.selectedCheckinMission).pipe(
        tap((mission) => {
            if (mission) {
                this.radiusControl.disable();
            }
        }),
        map((mission) => !!mission)
    );

    protected readonly categoryControl = new FormControl<FlightCategoriesValues>(this.flightStatusParams.flightCategory, {
        validators: Validators.required,
        nonNullable: true,
    });
    protected readonly subcategoryControl = new FormControl<string>(this.flightStatusParams.flightSubcategory, {
        validators: Validators.required,
        nonNullable: true,
    });
    protected readonly flightCategoryTranslationKeyControl = new FormControl<string>(this.flightStatusParams.flightCategoryTranslationKey, {
        validators: Validators.required,
        nonNullable: true,
    });
    protected readonly flightSubcategoryTranslationKeyControl = new FormControl<string>(
        this.flightStatusParams.flightSubcategoryTranslationKey,
        {
            validators: Validators.required,
            nonNullable: true,
        }
    );
    protected readonly weightRangeControl = new FormControl<Weight>(this.flightStatusParams.uavWeight, {
        validators: Validators.required,
        nonNullable: true,
    });
    protected readonly heightRangeControl = new FormControl<number>(this.flightStatusParams.flightHeight, {
        validators: [Validators.required, Validators.min(MIN_ALTITUDE_VALUE), Validators.max(MAX_ALTITUDE_VALUE)],
        nonNullable: true,
    });
    protected readonly radiusControl = new FormControl<number>(this.flightStatusParams.radius, {
        validators: [Validators.required, Validators.min(MIN_RADIUS_VALUE), Validators.max(MAX_RADIUS_VALUE)],
        nonNullable: true,
    });
    protected readonly startTimeControl = new FormControl<number>(this.flightStatusParams.startTime, {
        validators: [Validators.required, Validators.min(MIN_MINUTES_VALUE), Validators.max(MAX_MINUTES_VALUE)],
        nonNullable: true,
    });
    protected readonly durationControl = new FormControl<number>(this.flightStatusParams.duration, {
        validators: [Validators.required, Validators.min(MIN_DURATION_VALUE), Validators.max(MAX_DURATION_VALUE)],
        nonNullable: true,
    });
    protected readonly statusForm: FormGroup<StatusFormGroup> = new FormGroup({
        flightCategory: this.categoryControl,
        flightSubcategory: this.subcategoryControl,
        flightCategoryTranslationKey: this.flightCategoryTranslationKeyControl,
        flightSubcategoryTranslationKey: this.flightSubcategoryTranslationKeyControl,
        uavWeight: this.weightRangeControl,
        flightHeight: this.heightRangeControl,
        radius: this.radiusControl,
        startTime: this.startTimeControl,
        duration: this.durationControl,
    });
    protected readonly isButtonDisabled$ = this.statusForm.valueChanges.pipe(map(() => !this.statusForm.valid));

    constructor() {
        const maxMissionAltitude = this.store.selectSnapshot(DroneTowerMobileState.selectedCheckinMission)?.maxHeight;
        const maxAltitudeRange = maxMissionAltitude ? maxMissionAltitude : MAX_ALTITUDE_VALUE;
        this.localStore.setState({
            maxAltitude: maxAltitudeRange,
            maxMissionAltitude: maxMissionAltitude,
            openedPanel: this.getOpenedCategory(),
        });
    }

    public ngOnInit() {
        this.maxAltitude$.pipe(untilDestroyed(this)).subscribe((maxHeight) => {
            this.heightRangeControl.setValidators([Validators.required, Validators.min(MIN_ALTITUDE_VALUE), Validators.max(maxHeight)]);
            this.heightRangeControl.updateValueAndValidity();
        });
    }

    protected changeSelectedCategoryOptions(flightCategory: FlightCategory) {
        this.updateCategory(flightCategory);
        if (flightCategory.category === this.FlightCategoriesValues.OPEN) {
            if (this.weightRangeControl.value === Weight.Medium) {
                this.updateSubcategory(this.capabilities.subcategories[this.FlightCategoriesValues.OPEN][2]);
            }
            if (this.weightRangeControl.value === Weight.MediumLight) {
                this.updateSubcategory(this.capabilities.subcategories[this.FlightCategoriesValues.OPEN][1]);
            }
            if (this.weightRangeControl.value === Weight.Light) {
                this.updateSubcategory(this.capabilities.subcategories[this.FlightCategoriesValues.OPEN][0]);
            }
        }
        if (flightCategory.category === this.FlightCategoriesValues.SPECIFIC) {
            this.updateSubcategory(this.capabilities.subcategories[this.FlightCategoriesValues.SPECIFIC][0]);
        }
    }

    protected changeSelectedSubCategoryOptions(option: string, subcategory: FlightSubcategory) {
        this.updateSubcategory(subcategory);
    }

    protected changeSelectedWeightOptions(option: Weight) {
        this.weightRangeControl.setValue(option);
        if (option === Weight.Heavy) {
            this.updateCategory({ category: FlightCategoriesValues.SPECIFIC, name: FlightCategoriesTranslationKeysValues.SPECIFIC });
            this.updateSubcategory(this.capabilities.subcategories[this.FlightCategoriesValues.SPECIFIC][0]);
        }
        if (option === Weight.MediumLight && this.categoryControl.value === this.FlightCategoriesValues.OPEN) {
            this.updateSubcategory(this.capabilities.subcategories[this.FlightCategoriesValues.OPEN][1]);
        }
        if (option === Weight.Medium && this.categoryControl.value === this.FlightCategoriesValues.OPEN) {
            this.updateSubcategory(this.capabilities.subcategories[this.FlightCategoriesValues.OPEN][2]);
        }
    }

    protected saveDetails() {
        this.statusForm.updateValueAndValidity();
        if (this.statusForm.valid) {
            this.store
                .dispatch(new DroneTowerMobileActions.SetFlightStatusParams(this.convertFormValueToFlightStatusParams()))
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.router.navigate(["/"]);
                });
        }
    }

    private getOpenedCategory(): FlightConditionCategory {
        return this.activatedRoute.snapshot.queryParams[OPENED_FLIGHT_CATEGORY_QUERY_PARAM_NAME] ?? FlightConditionCategory.UAVCategory;
    }

    private updateCategory(flightCategory: FlightCategory) {
        this.categoryControl.setValue(flightCategory.category);
        this.flightCategoryTranslationKeyControl.setValue(flightCategory.name);
    }

    private updateSubcategory(flightSubcategory: FlightSubcategory) {
        this.subcategoryControl.setValue(flightSubcategory.subcategory);
        this.flightSubcategoryTranslationKeyControl.setValue(flightSubcategory.name);
    }

    private convertFormValueToFlightStatusParams(): Partial<FlightStatusParams> {
        return {
            ...this.statusForm.getRawValue(),
            startTime: +this.statusForm.controls.startTime.value,
            radius: +this.statusForm.controls.radius.value,
            duration: +this.statusForm.controls.duration.value,
            flightHeight: +this.statusForm.controls.flightHeight.value,
        };
    }
}
